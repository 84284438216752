import React, { useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import MobileNavLink from "./Components/MobileNavLink";
import { GlobalStateContext } from "../../common/contexts/GlobalContext";
import { useAuthState } from "../../common/contexts/AuthContext";
import { StickyMobileNav, StickyMobileNavItem } from "./styles";
import FeedbackAlert from "../FeedbackAlert";
import Modal from "../../Components/Modal";
import Alert from "../../Components/Alert";
import IconFixtures from "../../Components/Icons/iconFixtures";
import IconHome from "../../Components/Icons/iconHome";
import theme from "../../Pages/theme";

const StickyMobileFooterNav = () => {
  const { appState } = useContext(GlobalStateContext);
  const { userTeams } = appState;
  const { token } = useAuthState();
  const { pathname } = useLocation();
  const [displayFeedbackAlert, setDisplayFeedbackAlert] = useState(false);
  const [displaySuccessAlert, setDisplaySuccessAlert] = useState(null);

  const generateMyTeamLink = () => {
    if (token && userTeams && userTeams.length > 0) {
      return `/my-team/${userTeams[0].id}`;
    }
    return "/team-select";
  };

  const generateLeaguesLink = () => {
    if (token && userTeams && userTeams.length > 0) {
      return "/leagues";
    }
    return "/team-select";
  }
  return (
    <>
      <StickyMobileNav data-testid="sticky-footer-nav">
        <ul>
          <StickyMobileNavItem>
            <MobileNavLink to="/" text="Home" module="bottom navbar" position="1">
              <IconHome
                fill={pathname === "/" ? theme.primary : theme.silverChalice}
              />
              <span>Home</span>
            </MobileNavLink>
          </StickyMobileNavItem>
          <StickyMobileNavItem>
            <MobileNavLink to={generateMyTeamLink()} text="My Team" module="bottom navbar" position="2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20.38"
                height="20"
                viewBox="0 0 25 24"
              >
                <path
                  className="nav-icon"
                  d="M16.426 8.29l-1.386.397-.34-1.05 3.263-.931.26 1.13.028.022-.017.02.458 1.99-1.107.22-.26-1.131c-1.779 2.142-3.733 4.674-5.862 7.594l-.93-.625c2.137-2.932 4.1-5.477 5.893-7.635zm5.167-1.745c-1.882 0-3.408-1.465-3.408-3.272C18.185 1.465 19.711 0 21.593 0 23.474 0 25 1.465 25 3.273c0 1.807-1.526 3.272-3.407 3.272zM7.963 24c-1.882 0-3.408-1.465-3.408-3.273 0-1.807 1.526-3.272 3.408-3.272s3.407 1.465 3.407 3.272C11.37 22.535 9.845 24 7.963 24zm13.062-6l1.703 1.636-1.135 1.091-1.704-1.636-1.704 1.636-1.136-1.09L18.753 18l-1.704-1.636 1.136-1.091 1.704 1.636 1.704-1.636 1.135 1.09L21.025 18zM9.667 8.182l1.703 1.636-1.136 1.091-1.703-1.636-1.704 1.636-1.136-1.09 1.704-1.637L5.69 6.545l1.136-1.09L8.531 7.09l1.703-1.636 1.136 1.09-1.703 1.637zM3.757 3.38C1.1 8.654.995 13.204 3.402 17.082l-.975.555c-2.6-4.191-2.506-9.078.246-14.61L1.5 3.571 1 2.589l3.078-1.431 1.214 3.01-1.059.39-.474-1.178z"
                />
              </svg>
              <span>My Team</span>
            </MobileNavLink>
          </StickyMobileNavItem>
          <StickyMobileNavItem>
            <MobileNavLink to="/stats" text="Stats" module="bottom navbar" position="3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 30 30"
              >
                <path
                  className="nav-icon"
                  d="M5,15 L9,15 C9.55228475,15 10,15.4477153 10,16 L10,25 C10,25.5522847 9.55228475,26 9,26 L5,26 C4.44771525,26 4,25.5522847 4,25 L4,16 C4,15.4477153 4.44771525,15 5,15 Z M13,11 L17,11 C17.5522847,11 18,11.4477153 18,12 L18,25 C18,25.5522847 17.5522847,26 17,26 L13,26 C12.4477153,26 12,25.5522847 12,25 L12,12 C12,11.4477153 12.4477153,11 13,11 Z M21,4 L25,4 C25.5522847,4 26,4.44771525 26,5 L26,25 C26,25.5522847 25.5522847,26 25,26 L21,26 C20.4477153,26 20,25.5522847 20,25 L20,5 C20,4.44771525 20.4477153,4 21,4 Z"
                />
              </svg>
              <span>Stats</span>
            </MobileNavLink>
          </StickyMobileNavItem>
          <StickyMobileNavItem>
            <MobileNavLink to={generateLeaguesLink()} text="Leagues" module="bottom navbar" position="4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="20"
                viewBox="0 0 32 20"
              >
                <path
                  className="nav-icon"
                  d="M3.236 11.07a4.606 4.606 0 0 0 3.671 1.83 4.606 4.606 0 0 0 3.672-1.83c.316.15.613.31.885.476.067.228.15.45.25.662-1.903.912-3.2 2.196-3.2 3.592 0 .135-.003.248-.007.484-.01.462-.005.85.027 1.182a35.59 35.59 0 0 1-1.627.034c-3.183 0-4.97-.337-5.779-.965-.55-.428-.65-.87-.622-2.23.005-.243.007-.36.007-.505 0-.927 1.094-1.961 2.723-2.73zM9.5 5.606V6.5h.638c.259.509.405 1.087.405 1.7 0 2.045-1.63 3.7-3.636 3.7-2.006 0-3.636-1.655-3.636-3.7S4.9 4.5 6.907 4.5c1.015 0 1.933.423 2.593 1.106zm11.736 5.464a4.606 4.606 0 0 0 3.671 1.83 4.606 4.606 0 0 0 3.672-1.83c1.628.769 2.722 1.803 2.722 2.73 0 .144.002.262.007.504.027 1.361-.071 1.803-.622 2.23-.81.63-2.595.966-5.779.966-.59 0-1.13-.012-1.626-.034.03-.333.036-.72.027-1.182a22.12 22.12 0 0 1-.007-.484c0-1.396-1.297-2.68-3.2-3.592.098-.213.182-.434.25-.662.272-.167.568-.326.885-.476zm.44-4.57h.824V5.427a3.588 3.588 0 0 1 2.407-.927c2.007 0 3.636 1.655 3.636 3.7s-1.63 3.7-3.636 3.7c-2.006 0-3.636-1.655-3.636-3.7 0-.613.146-1.191.405-1.7zm-2.097 6.57c1.628.769 2.722 1.803 2.722 2.73 0 .144.002.262.007.504.027 1.361-.071 1.803-.622 2.23-.81.63-2.595.966-5.779.966-3.183 0-4.97-.337-5.779-.965-.55-.428-.65-.87-.622-2.23.005-.243.007-.36.007-.505 0-.927 1.094-1.961 2.723-2.73a4.606 4.606 0 0 0 3.671 1.83 4.606 4.606 0 0 0 3.672-1.83zm-3.672.83c-2.006 0-3.636-1.655-3.636-3.7s1.63-3.7 3.636-3.7c2.007 0 3.636 1.655 3.636 3.7s-1.63 3.7-3.636 3.7zM10.5.803l2.554 1.255L16.065.56l2.937 1.5L21.5.81V5.5h-11V.803z"
                />
              </svg>
              <span>Leagues</span>
            </MobileNavLink>
          </StickyMobileNavItem>
          <StickyMobileNavItem>
            <MobileNavLink to="/fixtures" text="Fixtures" module="bottom navbar" position="5">
              <IconFixtures
                fill={
                  pathname.includes("/fixtures")
                    ? theme.primary
                    : theme.silverChalice
                }
              />
              <span>Fixtures</span>
            </MobileNavLink>
          </StickyMobileNavItem>
        </ul>
        {displayFeedbackAlert ? (
          <FeedbackAlert
            setDisplaySuccessAlert={(e) => setDisplaySuccessAlert(e)}
            close={() => setDisplayFeedbackAlert(false)}
          />
        ) : null}
      </StickyMobileNav>
      {displaySuccessAlert ? (
        <Modal>
          <Alert
            error={displaySuccessAlert}
            setError={setDisplaySuccessAlert}
          />
        </Modal>
      ) : null}
    </>
  );
};

export default StickyMobileFooterNav;

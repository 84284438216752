import React, { useState } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import ContentContainer from "../../Components/ContentContainer";
import Link from "../../Components/Links/Link";
import ExternalLink from "../../Components/Links/ExternalLink";
import StickyMobileFooterNav from "../StickyMobileFooterNav";
import FeedbackAlert from "../FeedbackAlert";
import Modal from "../../Components/Modal";
import Alert from "../../Components/Alert";
import { sendInterfaceEvent, sendNavigationEvent } from "../../common/utilities/analytics";

import {
  SiteFooter,
  FooterContainer,
  FooterRow,
  FooterLogoRow,
  SkySportsLogo,
  GambleAwareLogos,
  FeedbackButton,
  FooterList,
  FooterLink,
  FooterButton,
} from "./styles";

const Footer = ({ routes }) => {
  const CDN_PATH = process.env.SKY_BET_FANTASY_CDN_ENDPOINT;
  const { pathname } = useLocation();
  const [displayFeedbackAlert, setDisplayFeedbackAlert] = useState(false);
  const [displaySuccessAlert, setDisplaySuccessAlert] = useState(null);

  const renderGambleAwareText = () => {
    if (window.Android) {
      return (
        <p className="gambling-app-text">
          This is a real money gambling app. Please gamble responsibly and only
          bet what you can afford. For gambling addiction help and support,
          please contact BeGambleAware at{" "}
          <a href="tel:0808 8020 133">0808 8020 133</a> or visit{" "}
          <a
            href="https://www.begambleaware.org/"
            target="_blank"
            rel="noreferrer"
          >
            https://www.begambleaware.org/
          </a>
        </p>
      );
    }

    return null;
  };

  const funStopPressed = () => {
    sendNavigationEvent({
      text: "when the fun stops stop logo",
      module: "footer",
      url: "http://www.whenthefunstops.co.uk/",
      position: "1"
    });
  }

  const gambleAwarePressed = () => {
    sendNavigationEvent({
      text: "be gamble aware logo",
      module: "footer",
      url: "http://www.begambleaware.org/",
      position: "2"
    });
  }

  const leaveFeedbackPressed = () => {
    sendInterfaceEvent({
      text: "leave feedback",
      module: "footer"
    });
    setDisplayFeedbackAlert(true)
  }

  return (
    <>
      <SiteFooter data-testid="footer">
        <ContentContainer>
          <FooterContainer>
            <FooterRow>
              <div>
                <SkySportsLogo
                  src={`${CDN_PATH}/assets/sky-sports-colour.png`}
                  alt="Skysports Logo"
                />{" "}
                © {dayjs().year()} Sky UK Ltd.
              </div>
              <div>
                <FeedbackButton
                  type="button"
                  onClick={() => leaveFeedbackPressed(true)}
                >
                  Leave Feedback
                </FeedbackButton>
              </div>
            </FooterRow>
            <FooterRow>
              <FooterList>
                <FooterLink>
                  <ExternalLink 
                    href="https://support.skybet.com/app/answers/detail/a_id/80"
                    module="footer" 
                    position="1" 
                    text="Accessibility"
                  >
                    Accessibility
                  </ExternalLink>
                </FooterLink>
                <FooterLink>
                  <Link 
                    data-testid="privacy" 
                    to="/privacy"
                    module="footer" 
                    position="2" 
                    text="Privacy and Cookies Notice"
                  >
                    Privacy and Cookies Notice
                  </Link>
                </FooterLink>
                <FooterLink>
                  <FooterButton type="button" onClick={() => {
                    sendInterfaceEvent({
                      text: "cookie management",
                      module: "footer"
                    });
                    if (window.OneTrust) {
                      window.OneTrust.ToggleInfoDisplay();
                    }
                  }}>
                    <span>Cookie Management</span>
                  </FooterButton>
                </FooterLink>
                <FooterLink>
                  <Link 
                    data-testid="terms" 
                    to="/terms"
                    module="footer" 
                    position="4" 
                    text="Terms and Conditions"
                  >
                    Terms and Conditions
                  </Link>
                </FooterLink>
                <FooterLink>
                  <ExternalLink 
                    href="https://www.skybet.com/secure/identity/m/recover/fantasyfootball"
                    module="footer" 
                    position="5" 
                    text="Recover Details"
                  >
                    Recover Details
                  </ExternalLink>
                </FooterLink>
                <FooterLink>
                  <ExternalLink 
                    href="https://www.skybet.com/secure/identity/m/faq/fantasyfootball"
                    module="footer" 
                    position="6" 
                    text="Account FAQs"
                  >
                    Account FAQs
                  </ExternalLink>
                </FooterLink>
                <FooterLink>
                  <Link 
                    data-testid="games-faqs" 
                    to="/faqs"
                    module="footer" 
                    position="7" 
                    text="Games FAQs"
                  >
                    Games FAQs
                  </Link>
                </FooterLink>
                <FooterLink>
                  <Link 
                    data-testid="contact" 
                    to="/contact"
                    module="footer" 
                    position="8" 
                    text="Contact Us"
                  >
                    Contact Us
                  </Link>
                </FooterLink>
              </FooterList>
            </FooterRow>
            <FooterRow>
              <p>
                Match fixtures reproduced under licence from Football DataCo
                Limited. All rights reserved. Licence number
                NEWMEDIA/SKYBET/115793a.
              </p>
              <p>
                Sky Bet is licensed and regulated by the UK Gambling Commission
                (licence number 38718). 18+
              </p>
              {renderGambleAwareText()}
            </FooterRow>
            <FooterLogoRow>
              <GambleAwareLogos>
                <a
                  href="http://www.whenthefunstops.co.uk/"
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => funStopPressed()}
                >
                  <img
                    src={`${CDN_PATH}/assets/when-the-fun-stops-stop.png`}
                    alt="When The Fun Stops Logo"
                  />
                </a>
                <a
                  href="http://www.begambleaware.org/"
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => gambleAwarePressed()}
                >
                  <img
                    src={`${CDN_PATH}/assets/be-gamble-aware.png`}
                    alt="Be Gamble Aware Logo"
                  />
                </a>
              </GambleAwareLogos>
            </FooterLogoRow>
          </FooterContainer>
        </ContentContainer>
        {pathname !== "/team-select" ? (
          <StickyMobileFooterNav routes={routes} />
        ) : null}
      </SiteFooter>
      {displayFeedbackAlert ? (
        <FeedbackAlert
          setDisplaySuccessAlert={(e) => setDisplaySuccessAlert(e)}
          close={() => setDisplayFeedbackAlert(false)}
        />
      ) : null}
      {displaySuccessAlert ? (
        <Modal>
          <Alert
            error={displaySuccessAlert}
            setError={setDisplaySuccessAlert}
          />
        </Modal>
      ) : null}
    </>
  );
};

Footer.propTypes = {
  routes: PropTypes.instanceOf(Array),
};

Footer.defaultProps = {
  routes: [],
};

export default Footer;

/* eslint-disable no-use-before-define */
import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import {
  BaseTokenRequestHandler,
  AuthorizationNotifier,
  FetchRequestor,
} from "@openid/appauth";
import { useHistory, useLocation } from "react-router-dom";
import {
  logout,
  loginUser,
  useAuthDispatch,
  useAuthState,
} from "../../common/contexts/AuthContext";
import {
  getConfig,
  getHandler,
  requestNewAccessToken,
  requestSilentAuthorization,
  requestToken,
  revokeRequest,
  validateAccessToken,
} from "../../common/utilities/authHandler";
import getQueryParams from "../../common/utilities/getQueryParams";
import { GlobalStateContext } from "../../common/contexts/GlobalContext";
import { getUserTeams } from "../../common/utilities/api";
import { sendMetaData } from '../../common/utilities/analytics';
import Modal from "../Modal";
import Alert from "../Alert";

function AuthWrapper({ children }) {
  const { appState, dispatch } = useContext(GlobalStateContext);
  const { activeCompetitionId } = appState;
  const { token } = useAuthState();
  const history = useHistory();
  const { pathname, search } = useLocation();
  const authDispatch = useAuthDispatch();
  const [alertError, setAlertError] = useState(null);

  const accessTokenValidation = async () => {
    const validToken = await validateAccessToken();

    return validToken;
  };

  function generateRedirectPath(currentPath) {
    if (
      currentPath.includes("/my-team") ||
      currentPath.includes("/team-select")
    ) {
      return "/my-team";
    }
    return currentPath;
  }

  const handleAuth = () => {
    const tokenHandler = new BaseTokenRequestHandler(new FetchRequestor());
    const authorizationHandler = getHandler();
    const notifier = new AuthorizationNotifier();
    authorizationHandler.setAuthorizationNotifier(notifier);

    notifier.setAuthorizationListener((request, response, error) => {
      if (response) {
        const tokenRequest = requestToken(response, request);

        const configuration = getConfig();

        tokenHandler
          .performTokenRequest(configuration, tokenRequest)
          .then(async (oResponse) => {
            sessionStorage.setItem("oauth_accessToken", oResponse.accessToken);
            sessionStorage.setItem(
              "oauth_refreshToken",
              oResponse.refreshToken
            );
            sessionStorage.setItem("oauth_state", request.state);
            sessionStorage.setItem(
              "oauth_codeVerifier",
              request.internal.code_verifier
            );
            sessionStorage.setItem("oauth_idToken", oResponse.idToken);
            const validToken = accessTokenValidation();
            if (validToken) {
              try {
                const loginResponse = await loginUser(authDispatch, {
                  token: oResponse.idToken,
                  accessToken: oResponse.accessToken,
                });
                if (!loginResponse) return;
              } catch (loginError) {
                console.log(loginError);
              }
            }
          })
          .catch((oError) => {
            console.log(oError);
          });
      }
      if (error) {
        // If there is an error reponse from the OAuth, redirect the user back to page they came from
        const redirectPath =
          sessionStorage.getItem("oauth_loginRedirectPath") || "/";
        // Added check for the pages that we require the user to be logged in so we don't get caught in a loop
        if (
          redirectPath.includes("/my-team") ||
          redirectPath.includes("/team-select") ||
          redirectPath.includes("/leagues") ||
          redirectPath.includes("/leaderboard")
        ) {
          history.push("/");
        } else {
          history.push(redirectPath);
        }
        sessionStorage.removeItem("oauth_loginRedirectPath");
      }
      return () => {};
    });

    authorizationHandler.completeAuthorizationRequestIfPossible();
  };

  const authorizeCheck = () => {
    if (window.Android) {
      window.Android.login();
      return () => {};
    }
    sessionStorage.setItem(
      "oauth_loginRedirectPath",
      generateRedirectPath(pathname)
    );
    sessionStorage.setItem("oauth_loginRedirectSearch", search);
    const authorizationHandler = getHandler();

    const configuration = getConfig();

    const authRequest = requestSilentAuthorization();
    authorizationHandler.performAuthorizationRequest(
      configuration,
      authRequest
    );

    sessionStorage.setItem("oauth_silentCheck", 1);
    return () => {};
  };

  const loginAndroid = () => {
    // check for window.Android
    if (window.Android) {
      // Call getAuth
      const data = window.Android.getAuth();
      // Check if the data is equal to 'error' and parse data if not
      // eslint-disable-next-line eqeqeq
      const response =
        data === "error" || data === "null" ? null : JSON.parse(data);
      // Check if there is data
      if (response) {
        // Store response data in state
        authDispatch({
          type: "LOGIN_SUCCESS",
          payload: {
            token: response.token,
            accessToken: response.accessToken,
            firstName: response.firstName,
            supportedTeamId: response.supportedTeamId,
            canLinkTo: response.canLinkTo,
            fullReg: response.fullReg,
            userId: response.userId,
          },
        });
      } else {
        logout(authDispatch, history);
        return () => {};
      }
      // Redirect to homepage
      history.push("/");
    }
    return () => {};
  };

  useEffect(() => {
    if (window.Android) {
      window.authorised = new Event("authorised");
      window.addEventListener("authorised", loginAndroid, false);
    }
    return () => window.removeEventListener("authorised", loginAndroid, false);
  }, []);

  useEffect(() => {
    if (window.Android) return () => {};
    if (window.location.search && window.location.search.startsWith("?ios"))
      return () => {};
    const requestLogin = async () => {
      const validToken = await accessTokenValidation();
      if (validToken) {
        try {
          const loginResponse = await loginUser(authDispatch, {
            token: sessionStorage.getItem("oauth_idToken"),
          });
          if (!loginResponse) return;
        } catch (loginError) {
          console.log(loginError);
        }
      } else if (sessionStorage.getItem("oauth_refreshToken") !== null) {
        sessionStorage.setItem(
          "oauth_loginRedirectPath",
          generateRedirectPath(pathname)
        );
        sessionStorage.setItem("oauth_loginRedirectSearch", search);
        requestNewAccessToken(authDispatch);
      } else {
        logout(authDispatch, history);
      }
    };
    if (sessionStorage.getItem("account_upgrading") !== null) {
      revokeRequest();
      logout(authDispatch, history);
      sessionStorage.removeItem("account_upgrading");
      return () => {};
    }
    if (sessionStorage.getItem("oauth_accessToken") !== null) {
      sessionStorage.setItem(
        "oauth_loginRedirectPath",
        generateRedirectPath(pathname)
      );
      sessionStorage.setItem("oauth_loginRedirectSearch", search);
      requestLogin();
      return () => {};
    }
    if (!sessionStorage.getItem("oauth_silentCheck")) {
      sessionStorage.setItem(
        "oauth_loginRedirectPath",
        generateRedirectPath(pathname)
      );
      sessionStorage.setItem("oauth_loginRedirectSearch", search);
      authorizeCheck();
      return () => {};
    }
    
    // send meta data unless we are redirecting back from oauth
    let code = null;
    if (search) {
      const params = getQueryParams(search);
      code = params.code;
    }
    if (!code) {
      sendMetaData();
    }
    
    handleAuth();

    // Check if active JWT exists if no access token or refresh token exists at this point and clear storage out.
    if (
      sessionStorage.getItem("currentUser") !== null &&
      sessionStorage.getItem("oauth_accessToken") === null &&
      sessionStorage.getItem("oauth_refreshToken") === null
    ) {
      sessionStorage.removeItem("currentUser");
      sessionStorage.removeItem("userTeams");
      sessionStorage.removeItem("oauth_accessToken");
      sessionStorage.removeItem("oauth_refreshToken");
      sessionStorage.removeItem("oauth_idToken");
      sessionStorage.removeItem("oauth_codeVerifier");
      sessionStorage.removeItem("oauth_state");
      history.push("/");
    }
    return () => {};
  }, []);

  const getTeams = async (competitionId) => {
    const res = await getUserTeams(token, competitionId);
    const { data: { teams, code } = {} } = res;

    if (code !== 0) {
      setAlertError(res.data);
      dispatch({ type: "SET_FETCHED_TEAMS", payload: true });
      return () => {};
    }

    dispatch({ type: "SET_USER_TEAMS", payload: teams });
    if (teams && teams.length > 0) {
      sessionStorage.setItem("userTeams", JSON.stringify(teams));
    }
    return null;
  };

  useEffect(() => {
    if (
      token &&
      (pathname !== "/" ||
        (sessionStorage.getItem("oauth_loginRedirectPath") &&
          sessionStorage.getItem("oauth_loginRedirectPath") !== "/"))
    ) {
      const searchParams = new URLSearchParams(search);
      const compId = searchParams.get("compId");
      // If there is a compId in the URL then we want to leave the fetching of teams
      // to the Header component to prevent duplicate requests.
      if(compId) {
        return
      } 
        getTeams(activeCompetitionId);
    }
    if (search) {
      const { code, state } = getQueryParams(search);
      if (code && state && token) {
        const redirectTo =
          sessionStorage.getItem("oauth_loginRedirectPath") || "/";
        if (!redirectTo) return;
        sessionStorage.removeItem("oauth_loginRedirectPath");
        history.push(redirectTo);
      }
    }
  }, [search, token]);

  return (
    <>
      <div>{children}</div>
      {alertError ? (
        <Modal>
          <Alert error={alertError} setError={setAlertError} />
        </Modal>
      ) : null}
    </>
  );
}

AuthWrapper.propTypes = {
  children: PropTypes.element.isRequired,
};

export default AuthWrapper;

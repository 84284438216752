import React, { useContext, useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import { GlobalStateContext } from "../../common/contexts/GlobalContext";
import MaintenanceContainer from "./styles";
import SkySportsFantasyFootballLogo from "../Icons/skyFantasyLogo";

dayjs.extend(isBetween);
dayjs.extend(relativeTime);
dayjs.extend(utc);

export default function MaintenanceTakeoverWrapper({ children }) {
  const { appState } = useContext(GlobalStateContext);
  const { gameSetup: { maintenance } = {} } = appState;
  const [maintenanceActive, setMaintenanceActive] = useState(false);
  const [checkComplete, setCheckComplete] = useState(false);
  const interval = useRef();

  useEffect(() => {
    if (
      appState &&
      appState.gameSetup &&
      Object.keys(appState.gameSetup).length > 0 &&
      !maintenance
    ) {
      if (!checkComplete) {
        setCheckComplete(true);
      }
      return () => {};
    }
    interval.current = setInterval(() => {
      if (maintenance && Object.keys(maintenance).length > 0) {
        if (
          dayjs().isBetween(
            dayjs.utc(maintenance.start).local(),
            dayjs.utc(maintenance.end).local(),
            "second",
            "[]"
          )
        ) {
          setMaintenanceActive(true);
          setCheckComplete(true);
          return () => {};
        }
        if (
          !dayjs().isBetween(
            dayjs.utc(maintenance.start).local(),
            dayjs.utc(maintenance.end).local(),
            "second",
            "[]"
          )
        ) {
          setMaintenanceActive(false);
          setCheckComplete(true);
          return () => {};
        }
      }
      if (!checkComplete) {
        setCheckComplete(true);
      }
      return () => {};
    }, 1000);
    return () => {
      clearInterval(interval.current);
    };
  }, [maintenance]);

  useEffect(() => {
    if (checkComplete && !maintenanceActive) {
      clearInterval(interval.current);
    }
    return () => {};
  }, [maintenanceActive, checkComplete]);
  if (navigator.userAgent === "custom_ios_agent") {
    return (
      <MaintenanceContainer>
        <MaintenanceContainer.Inner>
          <SkySportsFantasyFootballLogo />
          <p>
            Our new platform for the 2021/22 is better than ever and we have a
            new app too!
          </p>
          <p>
            Go to the App Store and download the latest version of the app to
            enter Sky Sports Official Fantasy Football game for the upcoming
            season.
          </p>
          <p>
            The new app can only be supported by iOS version 13 and upwards.
            Please check your device is updated to the latest OS or you can
            access the game from your web browser at
            fantasyfootball.skysports.com.
          </p>
        </MaintenanceContainer.Inner>
      </MaintenanceContainer>
    );
  }
  if (navigator.userAgent === "custom_android_agent") {
    return (
      <MaintenanceContainer>
        <MaintenanceContainer.Inner>
          <SkySportsFantasyFootballLogo />
          <p>
            Our new platform for the 2021/22 is better than ever and we have a
            new app too!
          </p>
          <p>
            Go to the Play Store and download the latest version of the app to
            enter Sky Sports Official Fantasy Football game for the upcoming
            season.
          </p>
          <p>
            The new app can only be supported by Android version 6 and upwards.
            Please check your device is updated to the latest OS or you can
            access the game from your web browser at
            fantasyfootball.skysports.com.
          </p>
        </MaintenanceContainer.Inner>
      </MaintenanceContainer>
    );
  }
  if (checkComplete) {
    if (maintenanceActive) {
      return (
        <MaintenanceContainer>
          <MaintenanceContainer.Inner>
            <SkySportsFantasyFootballLogo />
            <p>{appState.gameSetup.maintenance.message}</p>
          </MaintenanceContainer.Inner>
        </MaintenanceContainer>
      );
    }
    return <div>{children}</div>;
  }
  return null;
}

MaintenanceTakeoverWrapper.propTypes = {
  children: PropTypes.element.isRequired,
};

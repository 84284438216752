import styled from "styled-components";

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  z-index: 100;
  width: 100vw;
  height: 100%;
  overflow-y: auto;
  > div {
    margin: ${({ playerCard }) => (playerCard === 1 ? null : "auto")};
  }
`;

export default ModalOverlay;

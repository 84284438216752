import React from "react";
import PropTypes from "prop-types";
import BtnSuccess from "./styles";

function SuccessButton({
  type,
  children,
  clickEvent,
  testid,
  disabled = false,
  feedback,
}) {
  return (
    <BtnSuccess
      type={type}
      onClick={clickEvent}
      data-testid={testid}
      disabled={disabled}
      feedback={feedback}
      className="success"
    >
      {children}
    </BtnSuccess>
  );
}

SuccessButton.propTypes = {
  type: PropTypes.string,
  children: PropTypes.node,
  clickEvent: PropTypes.func,
  testid: PropTypes.string,
  disabled: PropTypes.bool,
  feedback: PropTypes.bool,
};

SuccessButton.defaultProps = {
  type: "",
  children: null,
  clickEvent: () => {},
  testid: "btn-success",
  disabled: false,
  feedback: false,
};

export default SuccessButton;

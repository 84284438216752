import React, { useState, useEffect, useContext, useCallback } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { useLocation, useHistory, useParams } from "react-router-dom";
import GeneralButton from "../../Components/Buttons/GeneralButton";
import Link from "../../Components/Links/Link";
import CrossSellNav from "../../Components/CrossSellNav";
import SiteNav from "./SiteNav";
import SiteHeader from "./styles";
import MenuIcon from "./assets/images/menu_icon.png";
import ProfileIcon from "./assets/images/Icons_Profile.svg";
import ContentContainer from "../../Components/ContentContainer";
import LogoutPanel from "./LogoutPanel";
import {
  useAuthState,
  useAuthDispatch,
  logout,
} from "../../common/contexts/AuthContext";
import DeadlineBanner from "./Components/DeadlineBanner";
import IconClose from "../../Components/Icons/closeIcon";
import { GlobalStateContext } from "../../common/contexts/GlobalContext";
import useWindowSize from "../../common/custom_hooks/windowSize";
import theme from "../../Pages/theme";
import {
  authorizeCheck,
  revokeRequest,
} from "../../common/utilities/authHandler";
import FeedbackAlert from "../FeedbackAlert";
import Modal from "../../Components/Modal";
import Alert from "../../Components/Alert";
import SystemMessageTicker from "./Components/SystemMessageTicker";
import getURLWithConsent from "../../common/utilities/getURLWithConsent";
import CompetitionsSelector from "../../Components/CompetitionsSelector";
import { getUserTeams } from "../../common/utilities/api";
import { sendNavigationEvent } from "../../common/utilities/analytics";

dayjs.extend(isBetween);

function Header({ routes }) {
  const [openNav, setOpenNav] = useState(false);
  const [openLogoutPanel, setOpenLogoutPanel] = useState(false);
  const location = useLocation();
  const { teamId } = useParams();
  const authDispatch = useAuthDispatch();
  const { token, firstName } = useAuthState();
  const { appState, dispatch } = useContext(GlobalStateContext);
  const {
    wildcardActive,
    activeCompetition,
    activeCompetitionId,
    gameSetup,
    teamsFetched,
  } = appState;
  const history = useHistory();
  const { pathname } = useLocation();
  const desktopView = useWindowSize(theme.largeScreen);
  const [displayFeedbackAlert, setDisplayFeedbackAlert] = useState(false);
  const [displaySuccessAlert, setDisplaySuccessAlert] = useState(null);
  const showCompetitionSelector =
    token &&
    gameSetup.competitions?.length > 1 &&
    (pathname === "/" ||
      pathname.startsWith("/my-team") ||
      pathname === "/leagues" ||      
      pathname === "/fixtures");

  const AUTH_API = process.env.SKY_BET_FANTASY_OAUTH_ENVIRONMENT;
  const AUTH_REDIRECT = process.env.SKY_BET_FANTASY_OAUTH_REDIRECT;
  const CDN_PATH = process.env.SKY_BET_FANTASY_CDN_ENDPOINT;

  const searchParams = new URLSearchParams(location.search);
  const compId = +searchParams.get("compId");

  const redirectUser = (storedTeams) => {
    if (!token) {
      authorizeCheck();
      return () => {};
    }
    if (!teamsFetched) return () => {};
    if (pathname.startsWith("/my-team")) {
      if (storedTeams.length === 0) {
        history.push("/team-select");
        return () => {};
      }
      if (!teamId) {
        history.push(`/my-team/${storedTeams[0].id}`);
        return () => {};
      }
      return () => {};
    }
    if (pathname.startsWith("/leagues")) {
      if (storedTeams.length === 0) {
        history.push("/team-select");
        return () => {};
      }
      return () => {};
    }
    return () => {};
  };

  const getTeams = useCallback(async () => {
    const res = await getUserTeams(token, activeCompetition.id);
    const { data: { teams, code } = {} } = res;

    if (code !== 0) {
      dispatch({ type: "SET_FETCHED_TEAMS", payload: true });
      return () => {};
    }

    dispatch({ type: "SET_USER_TEAMS", payload: teams });
    redirectUser(teams);
    if (teams && teams.length > 0) {
      sessionStorage.setItem("userTeams", JSON.stringify(teams));
    }
    return null;
  }, [activeCompetition.id, token]);

  useEffect(() => {
    if (token) {
      getTeams();
      return () => {};
    }
    return () => {};
  }, [activeCompetitionId, token]);

  useEffect(() => {
    if(!compId) return;
    dispatch({ type: "SET_ACTIVE_COMPETITION_ID", payload: compId });
  }, [compId])

  useEffect(() => {
    if (openNav || openLogoutPanel) {
      document.querySelector("body").style.overflowY = "hidden";
      // For older IOS 12 devices need to add overflow: hidden on the html tag as well to prevent overscrolling of nav menu when open
      document.querySelector("html").style.overflowY = "hidden";
    } else {
      document.querySelector("body").style.overflowY = "auto";
      // For older IOS 12 devices remove inline styling when nav menu is closed
      document.querySelector("html").style.overflowY = null;
    }
  }, [openNav, openLogoutPanel]);

  const checkAuth = () => {
      authorizeCheck();

    return () => {};
  };

  const goToMyAccount = async () => {
    // add onetrust cookie consent string to URL
    const url = getURLWithConsent(
      `https://${AUTH_API}.skybetservices.com/account?client_id=fantasyfootball_web&redirect_uri=${AUTH_REDIRECT}`
    );
    window.open(url, "_self");
  };

  const handleLogout = () => {
    if (window.Android) {
      window.Android.softLogout();
      logout(authDispatch, history);
      return () => {};
    }
    revokeRequest();
    logout(authDispatch, history);
    return () => {};
  };

  const handleHardLogout = () => {
    if (!window.Android) return () => {};
    window.Android.hardLogout();
    logout(authDispatch, history);
    history.push("/");
    return () => {};
  };

  useEffect(() => {
    setOpenNav(false);
    setOpenLogoutPanel(false);
    return () => {};
  }, [location, token]);

  useEffect(() => {
    if(window?.AnalyticsWebInterface?.logPageView && document.title) {
      window.AnalyticsWebInterface.logPageView(document.title)
    } 
  }, [pathname, document.title])

  return (
    <>
      <CrossSellNav />
      <SiteHeader
        open={openNav && !desktopView}
        openLogoutPanel={openLogoutPanel && !desktopView}
        wildcardActive={
          wildcardActive &&
          activeCompetition?.wildcard &&
          !activeCompetition.wildcard.preseason
        }
        mobileView={!desktopView}
      >
        <ContentContainer>
          <div className="menu-button">
            <GeneralButton
              type="button"
              clickEvent={() => setOpenNav(!openNav)}
              testid="burger-menu-button"
            >
              <span>
                {!openNav ? (
                  <img src={MenuIcon} alt="Open Menu" />
                ) : (
                  <IconClose />
                )}
              </span>
            </GeneralButton>
          </div>

          <div className="logo">
            <Link to="/" text="fantasy logo" module="header" position="1" module_display_order="2">
              <img
                src={`${CDN_PATH}/assets/sky-fantasy-logo.png`}
                alt="Sky Fantasy Logo"
              />
            </Link>
          </div>
          <div className="account">
            {token !== "" ? (
              <>
                <SiteHeader.FirstName
                  onClick={() => {
                    if (window.Android) {
                      setOpenLogoutPanel(!openLogoutPanel)
                    }
                    else {
                      sendNavigationEvent({
                        text: "account",
                        module: "header",                    
                        order: "2",
                        position: "1",
                        url: `https://${AUTH_API}.skybetservices.com/account`
                      })
                      goToMyAccount()
                    }
                  }}
                >
                  <SiteHeader.ProfileIcon
                    src={ProfileIcon}
                    alt="Profile Icon"
                  />
                  <span className="first-name">{firstName}</span>
                </SiteHeader.FirstName>
              </>
            ) : (
              <span
                className="login-link"
                role="presentation"
                onClick={() => {
                  sendNavigationEvent({
                    text: "login / register",
                    module: "header",                    
                    order: "2",
                    position: "1",
                    url: `https://${AUTH_API}.skybetservices.com`
                  })
                  checkAuth();
                }}
              >
                <SiteHeader.ProfileIcon
                  className="login-profile-icon"
                  src={ProfileIcon}
                  alt="Profile Icon"
                />
                <span>Login / Register</span>
              </span>
            )}
          </div>
        </ContentContainer>
        {window.Android ? (
          <LogoutPanel
            openLogoutPanel={openLogoutPanel}
            handleLogout={handleLogout}
            handleHardLogout={handleHardLogout}
            close={() => setOpenLogoutPanel(!openLogoutPanel)}
          />
        ) : null}

        <SiteNav
          openNav={openNav}
          routes={routes}
          openFeedbackAlert={() => setDisplayFeedbackAlert(true)}
        />

        {!location.pathname.includes("/leagues") && token ? (
          <DeadlineBanner />
        ) : null}

        <SystemMessageTicker />
      </SiteHeader>
      {showCompetitionSelector ? (
        <CompetitionsSelector
          competitions={gameSetup.competitions}
          activeCompetitionId={activeCompetition.id}
        />
      ) : null}

      {displayFeedbackAlert ? (
        <FeedbackAlert
          setDisplaySuccessAlert={(e) => setDisplaySuccessAlert(e)}
          close={() => setDisplayFeedbackAlert(false)}
        />
      ) : null}

      {displaySuccessAlert ? (
        <Modal>
          <Alert
            error={displaySuccessAlert}
            setError={setDisplaySuccessAlert}
          />
        </Modal>
      ) : null}
    </>
  );
}

Header.propTypes = {
  routes: PropTypes.instanceOf(Array),
};

Header.defaultProps = {
  routes: [],
};

export default Header;

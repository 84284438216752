import styled from "styled-components";

const FeedbackAlertForm = styled.form`
  label {
    display: inline-block;
    margin: 15px 0 8px;
  }

  .small {
    font-size: 80%;
  }

  select {
    background: ${({ theme }) => theme.white};
  }

  input {
    color: ${({ theme }) => theme.primary};
    font-weight: 400;
  }
`;

FeedbackAlertForm.Textarea = styled.textarea`
  display: flex;
  width: 100%;
  max-width: 100%;
  height: 112px;
  border: 1px solid
    ${({ theme, error }) => (error ? theme.errorRed : theme.silver)};
  border-radius: 4px;
  padding: 0 5px;
  font-family: Sky;
  font-size: 1.0625rem;
  color: ${({ theme }) => theme.primary};
`;

FeedbackAlertForm.Submit = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 8px;

  button {
    display: block;
    width: 100%;
    max-width: 309px;
    margin: 0 auto;
    padding: 8px 16px;
    font-size: 1.25rem;
    line-height: 1.5;
    text-transform: capitalize;
  }
`;

export default FeedbackAlertForm;

import React, { useEffect, useContext, useRef } from "react";
import PropTypes from "prop-types";
import { GlobalStateContext } from "../../common/contexts/GlobalContext";
import { CompetitionsSelectorContainer, CompetitionButton } from "./styles";

export default function CompetitionsSelector({
  competitions,
  activeCompetitionId,
}) {
  const { dispatch } = useContext(GlobalStateContext);
  const activeCompetitionRef = useRef(null);

  useEffect(() => {
    if (activeCompetitionRef?.current) {
      activeCompetitionRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [activeCompetitionRef?.current]);

  return (
    <CompetitionsSelectorContainer>
      {competitions.map((competition) => (
        <CompetitionButton
          key={competition.id}
          ref={
            competition.id === activeCompetitionId ? activeCompetitionRef : null
          }
          tournamentMode={competition.type === "tourn"}
          multiComp={competitions.length > 2}
          active={competition.id === activeCompetitionId}
          onClick={() =>
            dispatch({
              type: "SET_ACTIVE_COMPETITION_ID",
              payload: competition.id,
            })
          }
        >
          {competition.name}
        </CompetitionButton>
      ))}
    </CompetitionsSelectorContainer>
  );
}

CompetitionsSelector.propTypes = {
  competitions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  activeCompetitionId: PropTypes.number.isRequired,
};

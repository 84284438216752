import styled from "styled-components";
import Button from "../Buttons/styles";

const GamesNav = styled.div`
  text-align: center;
  background: ${({ theme }) => theme.greyDark};
  color: ${({ theme }) => theme.white};
  height: 32px;
  overflow-x: auto;
  > div {
    display: flex;
    overflow: auto hidden;
    padding: 0 16px;
    margin: 0 auto;
    white-space: nowrap;
    @media (min-width: ${({ theme }) => `${theme.largeScreen}px`}) {
      justify-content: center;
    }
    a {
      display: flex;
      font-size: 0.875rem;
      color: ${({ theme }) => theme.white};
      margin: auto 6px;
      padding: 8px 0;
      img {
        display: block;
        height: 16px;
        width: auto;
        align-items: center;
      }
      &:last-child {
        padding-right: 10px;
      }
    }
  }
`;

GamesNav.Button = styled(Button)`
  display: flex;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.white};
  margin: auto 6px;
  padding: 8px 0;
  img {
    display: block;
    height: 16px;
    width: auto;
    align-items: center;
  }
`;

export default GamesNav;

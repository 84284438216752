import styled from "styled-components";
import Button from "../styles";

const BtnSuccess = styled(Button)`
  color: ${({ theme }) => theme.white};
  background: ${({ theme, disabled, feedback }) =>
    disabled && !feedback ? theme.boulderGreyTwo : theme.success};
  opacity: ${({ disabled, feedback }) =>
    disabled && feedback ? "0.65" : null};
  font-size: 1rem;
  font-weight: 500;
  padding: 6px 12px;
  border-radius: 5px;
  text-transform: uppercase;
`;

export default BtnSuccess;

import styled from "styled-components";
import Link from "../../../../Components/Links/Link";

export const StickyMobileNavLink = styled(Link)`
  border-radius: 0;
  padding: 8px 0;
  display: block;
  background-color: transparent;
  cursor: pointer;
  text-decoration: none;

  span {
    font-size: 10px;
    font-weight: ${({ active }) => (active ? 500 : 400)};
    color: ${({ theme, active }) =>
      active ? theme.primary : theme.silverChalice};
  }

  &:hover {
    text-decoration: none;
  }
`;

export const StickyMobileNavIconWrapper = styled.div`
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  svg {
    height: 20px;
    overflow: hidden;
    vertical-align: middle;
  }

  .nav-icon {
    fill: ${({ active, theme }) =>
      active ? theme.primary : theme.silverChalice};
  }
`;

import styled from "styled-components";
import Button from "../../../Components/Buttons/styles";
import SuccessButton from "../../../Components/Buttons/SuccessButton/styles";

const LogoutContainer = styled.div`
  position: fixed;
  top: 32px;
  right: 0;
  bottom: 0;
  width: 70vw;
  height: 100vh;
  transform: ${({ open }) => (!open ? "translateX(200%)" : "translateX(0)")};
  transition: transform 0.3s ease-in-out;
  z-index: 101;
  background: ${({ theme }) => theme.white};
  box-shadow: -4px 0 6px 0 rgba(0, 0, 0, 0.15);

  @media (min-width: ${({ theme }) => `${theme.smallScreen}px`}) {
    width: 60vw;
  }

  @media (min-width: ${({ theme }) => `${theme.largeScreen}px`}) {
    width: 40vw;
  }
`;

LogoutContainer.Header = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.secondary};
  height: 48px;
  padding: 0 8px;

  & > div {
    display: flex;

    &:nth-child(1) {
      flex: 1;
      justify-content: center;
    }

    &:nth-child(1) {
      align-items: center;
    }
  }

  img {
    max-width: 85px;
    height: auto;
    align-self: center;
  }

  @media (min-width: ${({ theme }) => `${theme.largeScreen}px`}) {
    height: 108px;
    padding: 0 16px;
  }
`;

LogoutContainer.Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px;
`;

LogoutContainer.AccountButton = styled(Button)`
  background: ${({ theme }) => theme.secondary};
  color: ${({ theme }) => theme.white};
  text-transform: capitalize;
  cursor: pointer;
  height: 44px;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 500;
  margin: 16px 0;
  width: 100%;
  max-width: 220px;

  @media (min-width: ${({ theme }) => `${theme.largeScreen}px`}) {
    max-width: 300px;
  }
`;

LogoutContainer.LogOutButton = styled(SuccessButton)`
  text-transform: capitalize;
  height: 44px;
  width: 100%;
  max-width: 220px;

  @media (min-width: ${({ theme }) => `${theme.largeScreen}px`}) {
    max-width: 300px;
  }
`;

LogoutContainer.HardLogOutButton = styled(Button)`
  text-transform: capitalize;
  font-weight: 500;
  text-decoration: underline;
  padding: 16px 0;
  margin: 8px auto;
  width: 100%;

  @media (min-width: ${({ theme }) => `${theme.largeScreen}px`}) {
    max-width: 300px;
  }
`;

LogoutContainer.CloseButton = styled(Button)`
  cursor: pointer;
`;

export default LogoutContainer;

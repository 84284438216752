export const isRequiredValidator = (name, value) =>
  value === ""
    ? { isValid: false, msg: `Please enter a ${name}` }
    : { isValid: true, msg: null };

export const pinValidator = (pin) => {
  const pinValid = /^[a-zA-Z0-9~@#$%^&*+=`|{}:;!.?"()[\]-]{4,6}$/.test(pin);

  return {
    isValid: pinValid,
    msg: pinValid ? null : "Please provide a 4-6 digit PIN",
  };
};

import React from "react";
import PropTypes from "prop-types";
import Modal from "../../Components/Modal";
import FeedbackAlertContainer from "./styles";
import Button from "../../Components/Buttons/GeneralButton";
import IconClose from "../../Components/Icons/closeIcon";
import Form from "./Modules/Form";

export default function FeedbackAlert({ setDisplaySuccessAlert, close }) {
  return (
    <Modal>
      <FeedbackAlertContainer>
        <FeedbackAlertContainer.Close>
          <Button type="button" clickEvent={close} testid="close-feedback">
            <IconClose fill="#4C4C4C" size={18} />
          </Button>
        </FeedbackAlertContainer.Close>
        <FeedbackAlertContainer.Main>
          <h2>Fantasy Football Feedback</h2>
          <p>
            We&apos;re always looking to improve Fantasy Football for our
            customers and we&apos;d really like to know what you think of
            Fantasy Football.
          </p>
          <Form
            showSuccessAlert={(e) => setDisplaySuccessAlert(e)}
            close={close}
          />
        </FeedbackAlertContainer.Main>
      </FeedbackAlertContainer>
    </Modal>
  );
}

FeedbackAlert.propTypes = {
  setDisplaySuccessAlert: PropTypes.func,
  close: PropTypes.func,
};

FeedbackAlert.defaultProps = {
  setDisplaySuccessAlert: () => {},
  close: () => {},
};

/* eslint-disable no-use-before-define */
import {
  RedirectRequestHandler,
  LocalStorageBackend,
  DefaultCrypto,
  AuthorizationServiceConfiguration,
  AuthorizationRequest,
  GRANT_TYPE_REFRESH_TOKEN,
  GRANT_TYPE_AUTHORIZATION_CODE,
  TokenRequest,
  BaseTokenRequestHandler,
  FetchRequestor,
  RevokeTokenRequest,
} from "@openid/appauth";
import { loginUser, logout } from "../contexts/AuthContext";
import { validateToken } from "./api";
import NoHashQueryStringUtils from "./noHashQueryStringUtils";

const AUTH_API = process.env.SKY_BET_FANTASY_OAUTH_ENVIRONMENT;
const AUTH_REDIRECT = process.env.SKY_BET_FANTASY_OAUTH_REDIRECT;

export const getConfig = () => {
  return new AuthorizationServiceConfiguration({
    authorization_endpoint: `https://${AUTH_API}.skybetservices.com/openid/v1/authorize`,
    token_endpoint: `https://${AUTH_API}.skybetservices.com/openid/v1/token`,
    revocation_endpoint: `https://${AUTH_API}.skybetservices.com/openid/v1/token_revoke`,
    end_session_endpoint: "",
  });
};

export const getHandler = () => {
  return new RedirectRequestHandler(
    new LocalStorageBackend(),
    new NoHashQueryStringUtils(),
    window.location,
    new DefaultCrypto()
  );
};

export const requestToken = (response, request) => {
  let extras = null;
  if (request && request.internal) {
    extras = {};
    extras.code_verifier = request.internal.code_verifier;
  }
  return new TokenRequest({
    client_id: "fantasyfootball_web",
    redirect_uri: `${AUTH_REDIRECT}`,
    grant_type: GRANT_TYPE_AUTHORIZATION_CODE,
    code: response.code,
    refresh_token: undefined,
    extras,
  });
};

export const validateAccessToken = async () => {
  const tokenValidation = await validateToken({
    client_id: "fantasyfootball_web",
    token: sessionStorage.getItem("oauth_accessToken"),
    token_type_hint: "access_token",
  });

  const { data: { active } = {} } = tokenValidation;

  return active;
};

export const requestRefreshToken = () => {
  return new TokenRequest({
    client_id: "fantasyfootball_web",
    redirect_uri: `${AUTH_REDIRECT}`,
    grant_type: GRANT_TYPE_REFRESH_TOKEN,
    code: undefined,
    refresh_token: sessionStorage.getItem("oauth_refreshToken"),
    extras: undefined,
  });
};

export const requestNewAccessToken = (authDispatch) => {
  // Access Token has expired - we should make a call using the refresh token to get a fresh set of tokens
  const tokenHandler = new BaseTokenRequestHandler(new FetchRequestor());

  const refreshTokenRequest = requestRefreshToken();

  const configuration = getConfig();

  tokenHandler
    .performTokenRequest(configuration, refreshTokenRequest)
    .then(async (oResponse) => {
      sessionStorage.setItem("oauth_accessToken", oResponse.accessToken);
      sessionStorage.setItem("oauth_refreshToken", oResponse.refreshToken);
      sessionStorage.setItem("oauth_idToken", oResponse.idToken);
      const activeToken = validateAccessToken();
      if (activeToken) {
        try {
          const loginResponse = await loginUser(authDispatch, {
            token: oResponse.idToken,
            accessToken: oResponse.accessToken,
          });
          if (!loginResponse) return;
        } catch (loginError) {
          console.log(loginError);
        }
      } else {
        logout(authDispatch);
      }
    })
    .catch((oError) => {
      console.log(oError);

      // the current refresh token has expired we need to login the user out
      sessionStorage.removeItem("currentUser");
      sessionStorage.removeItem("userTeams");
      sessionStorage.removeItem("oauth_accessToken");
      sessionStorage.removeItem("oauth_refreshToken");
      sessionStorage.removeItem("oauth_idToken");
      sessionStorage.removeItem("oauth_codeVerifier");
      sessionStorage.removeItem("oauth_state");
      window.location.href = "/"; // TODO: Change to history.push("/") when moving to own component?
    });
};

export const requestState = () => {
  return (
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15)
  );
};

export const getRequestExtras = () => {
  const extras = {};
  if (window && 
      window.TrackingConsent && 
      window.TrackingConsent.oneTrustSdkHasLoaded) {
    const groups = window.TrackingConsent.getConsentGroups();
    if (groups && groups.length > 0) {
      extras.consentGroups = groups.toString();        
    }    
  }
  return extras;
}

export const requestAuthorization = () => {
  return new AuthorizationRequest({
    client_id: "fantasyfootball_web",
    redirect_uri: `${AUTH_REDIRECT}`,
    scope: "openid",
    response_type: AuthorizationRequest.RESPONSE_TYPE_CODE,
    state: requestState(),
    extras: getRequestExtras(),
  });
};

export const requestSilentAuthorization = () => {
  return new AuthorizationRequest({
    client_id: "fantasyfootball_web",
    redirect_uri: `${AUTH_REDIRECT}`,
    scope: "openid",
    response_type: AuthorizationRequest.RESPONSE_TYPE_CODE,
    state: requestState(),
    extras: { prompt: "none" },
  });
};

export const revokeRequest = () => {
  const request = new RevokeTokenRequest({
    client_id: "fantasyfootball_web",
    token_type_hint: "refresh_token",
    token: sessionStorage.getItem("oauth_refreshToken"),
  });

  if (request) {
    const tokenHandler = new BaseTokenRequestHandler(new FetchRequestor());
    const config = getConfig();
    tokenHandler
      .performRevokeTokenRequest(config, request)
      .then((response) => {
        if (response) {
          sessionStorage.removeItem("currentUser");
          sessionStorage.removeItem("userTeams");
          sessionStorage.removeItem("oauth_accessToken");
          sessionStorage.removeItem("oauth_refreshToken");
          sessionStorage.removeItem("oauth_idToken");
          sessionStorage.removeItem("oauth_codeVerifier");
          sessionStorage.removeItem("oauth_state");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
};

function generateRedirectPath(currentPath) {
  if (
    currentPath.includes("/my-team") ||
    currentPath.includes("/team-select")
  ) {
    return "/my-team";
  }
  return currentPath;
}

export const authorizeCheck = () => {
  if (window.Android) {
    window.Android.login();
    return () => {};
  }
  sessionStorage.setItem(
    "oauth_loginRedirectPath",
    generateRedirectPath(window.location.pathname)
  );
  sessionStorage.setItem("oauth_loginRedirectSearch", window.location.search);
  const authorizationHandler = getHandler();
  const configuration = getConfig();
  const authRequest = requestAuthorization();
  authorizationHandler.performAuthorizationRequest(configuration, authRequest);

  return () => {};
};

import React, { useEffect, useContext } from "react";
import { GlobalStateContext } from "../../../../common/contexts/GlobalContext";
import { getSystemMessages } from "../../../../common/utilities/api";
import MessageTicker from "./styles";

export default function SystemMessageTicker() {
  const { appState, dispatch } = useContext(GlobalStateContext);
  const { systemMessages } = appState;

  const CDN_PATH = process.env.SKY_BET_FANTASY_CDN_ENDPOINT;

  const renderMessageIcon = (type) => {
    switch (type) {
      case "Message":
        return (
          <img
            src={`${CDN_PATH}/assets/icons-system-message.svg`}
            alt="System Message Icon"
          />
        );

      case "Warning":
        return (
          <img
            src={`${CDN_PATH}/assets/icons-system-warning.svg`}
            alt="System Warning Icon"
          />
        );

      case "Information":
        return (
          <img
            src={`${CDN_PATH}/assets/icons-system-information.svg`}
            alt="System Information Icon"
          />
        );

      default:
        return null;
    }
  };

  const fetchSystemMessages = async () => {
    await getSystemMessages().then((response) => {
      const { data } = response;
      if (data.code === 0) {
        dispatch({
          type: "STORE_SYSTEM_MESSAGES",
          payload: data.messages,
        });
        dispatch({ type: "STORE_BANNER_ADS", payload: data.ads });
      }
      return () => {};
    });
    return () => {};
  };

  useEffect(() => {
    fetchSystemMessages();
    return () => {};
  }, []);

  if (systemMessages.length === 0) return null;

  return (
    <MessageTicker type={systemMessages.map((item) => item.adType)[0]}>
      <MessageTicker.Inner
        stringLength={systemMessages.map((item) => item.message)[0].length}
      >
        <div>
          {systemMessages.map((item, key) => (
            <p key={parseInt(key, 10).toString()}>{item.message}</p>
          ))}
        </div>
        <div>
          {renderMessageIcon(systemMessages.map((item) => item.adType)[0])}
        </div>
        <MessageTicker.GradientBackground
          type={systemMessages.map((item) => item.adType)[0]}
        />
      </MessageTicker.Inner>
    </MessageTicker>
  );
}

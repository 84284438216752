import styled, { keyframes } from "styled-components";

const ScrollingTextAnimation = keyframes`
  0% { 
    transform: translateX(100vw); 
  }
  100% { 
    transform: translateX(-100%); 
  }
`;

const DesktopScrollingTextAnimation = keyframes`
  0% { 
    transform: translateX(95vw); 
  }
  100% { 
    transform: translateX(-100%); 
  }
`;

const LargeDesktopScrollingTextAnimation = keyframes`
  0% { 
    transform: translateX(65vw); 
  }
  100% { 
    transform: translateX(-100%); 
  }
`;

const MessageTicker = styled.div`
  background: ${({ theme, type }) => {
    if (type === "Warning") {
      return theme.lava;
    }

    if (type === "Information") {
      return theme.supernova;
    }

    if (type === "Message") {
      return theme.shamrockGreen;
    }

    return null;
  }};
`;

MessageTicker.Inner = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 2fr 0.25fr;
  align-items: center;
  max-width: 1140px;
  width: 100%;
  margin: 0 auto;
  padding: 8px 12px 8px 0;

  > div {
    &:nth-child(1) {
      white-space: nowrap;
      overflow: hidden;
    }

    &:nth-child(2) {
      justify-self: end;
      display: flex;
      z-index: 1;
    }
  }

  p {
    font-weight: 500;
    display: inline-block;
    animation: ${ScrollingTextAnimation}
      ${({ stringLength }) => `${120 * stringLength}ms`} linear infinite;
    color: ${({ theme }) => theme.white};
    @media (min-width: ${({ theme }) => `${theme.extraLargeScreen}px`}) {
      animation: ${DesktopScrollingTextAnimation}
        ${({ stringLength }) => `${120 * stringLength}ms`} linear infinite;
    }

    @media (min-width: ${({ theme }) => `${theme.superExtraLargeScreen}px`}) {
      animation: ${LargeDesktopScrollingTextAnimation}
        ${({ stringLength }) => `${120 * stringLength}ms`} linear infinite;
    }
  }
`;

MessageTicker.GradientBackground = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background: ${({ type }) => {
    if (type === "Warning") {
      return "linear-gradient(95deg, rgba(255, 64, 64, 0.03) 0%, rgba(210, 18, 26, 0.5) 20%, rgba(213, 22, 29, 0.79) 40%, rgba(214, 22, 29, 0.99) 81%, rgb(214, 23, 30) 100%)";
    }

    if (type === "Information") {
      return "linear-gradient(to right, rgba(255, 255, 0, 0.02) 0%, rgba(255, 198, 3, 0.33) 12%, rgba(252, 198, 12, 0.69) 32%, rgba(255, 200, 9, 0.89) 52%, rgba(254, 200, 10, 0.89) 100%)";
    }

    if (type === "Message") {
      return "linear-gradient(95deg, rgba(0, 64, 64, 0.03) 0%, rgba(0, 179, 26, 0.05) 1%, rgba(3, 157, 70, 0.36) 13%, rgba(4, 157, 74, 0.71) 33%, rgba(2, 158, 72, 0.9) 54%, rgb(4, 159, 73) 100%)";
    }

    return null;
  }};

  width: 100px;
  height: 100%;

  @media (min-width: ${({ theme }) => `${theme.largeScreen}px`}) {
    width: 220px;
  }
`;

export default MessageTicker;

import React from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import NavigationLink from "./styles";

function NavLink({ to, text, ...rest }) {
  const { pathname } = useLocation();
  return (
    <NavigationLink
      to={to}
      data-link={text}
      aria-label={text}
      text={text}
      active={
        pathname === to ||
        (to.includes("/my-team") && pathname.includes("/team-select")) ||
        ((to.includes("/my-team") ||
          to.includes("/faqs") ||
          to.includes("/help") ||
          to.includes("/prizes") ||
          to.includes("/stats") ||
          to.includes("/leagues") ||
          to.includes("/fixtures") ||
          to.includes("/help")) &&
          pathname.includes(to))
          ? 1
          : 0
      }
      {...rest}
    />
  );
}

NavLink.propTypes = {
  to: PropTypes.string,
  text: PropTypes.string,
};

NavLink.defaultProps = {
  to: "/",
  text: "",
};

export default NavLink;

import { sendMetaData } from '../../utilities/analytics';

const token = sessionStorage.getItem("currentUser")
  ? JSON.parse(sessionStorage.getItem("currentUser")).token
  : "";

const firstName = sessionStorage.getItem("currentUser")
  ? JSON.parse(sessionStorage.getItem("currentUser")).firstName
  : "";

const userId = sessionStorage.getItem("currentUser")
  ? JSON.parse(sessionStorage.getItem("currentUser")).userId
  : "";

const supportedTeamId = sessionStorage.getItem("currentUser")
  ? JSON.parse(sessionStorage.getItem("currentUser")).supportedTeamId
  : null;

export const initialState = {
  firstName,
  token,
  accessToken: "",
  supportedTeamId,
  loading: false,
  errorMessage: null,
  canLinkTo: [],
  products: [],
  fullReg: false,
  userId,
};

export const AuthReducer = (initState, action) => {
  switch (action.type) {
    case "REQUEST_LOGIN":
      return {
        ...initState,
        loading: true,
      };
    case "LOGIN_SUCCESS": {
      sendMetaData(action.payload.userId, action.payload.fullReg);
      return {
        ...initState,
        firstName: action.payload.firstName,
        token: action.payload.token,
        accessToken: action.payload.accessToken,
        supportedTeamId: action.payload.supportedTeamId,
        canLinkTo: action.payload.canLinkTo,
        fullReg: action.payload.fullReg,
        loading: false,
        errorMessage: null,
        userId: action.payload.userId,
      };
    }
    case "LOGOUT": {
      sendMetaData();
      return {
        ...initState,
        firstName: "",
        token: "",
        accessToken: "",
        supportedTeamId: null,
        canLinTo: [],
        fullReg: false,
        userId: null,
      };
    }
    case "LOGIN_ERROR":
      sendMetaData();
      return {
        ...initState,
        loading: false,
        errorMessage: action.error,
      };

    case "SET_CROSS_SELL_PRODUCTS":
      return {
        ...initState,
        products: action.payload,
      };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

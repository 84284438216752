import React, { lazy, useEffect, useState, Suspense } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { Switch, Route } from "react-router-dom";

export const Loader = <div />;

const Router = ({ routes }) => {
  const [routesWithPreload, setRoutesWithPreload] = useState(routes);
  useEffect(() => {
    setRoutesWithPreload(
      routesWithPreload.map((route) => {
        const copy = { ...route };
        const { fetchComponent } = copy;

        if (fetchComponent) {
          const preloadedComponent = fetchComponent();
          copy.component = lazy(() => preloadedComponent);
        }
        return copy;
      })
    );
  }, []);
  return (
    <Suspense fallback={Loader}>
      <Switch>
        {routesWithPreload.map(
          ({
            path,
            title,
            component: Component,
            routes: children,
            exact = true,
            ...rest
          }) => {
            return (
              <Route
                key={path || "/404"}
                path={path}
                exact={exact}
                component={(props) => (
                  <React.Fragment>
                    {Component ? (
                      <>
                        <Helmet>
                          <title>Sky Sports Fantasy Football | {title}</title>
                        </Helmet>
                        <Component {...props} />
                      </>
                    ) : null}
                    {children && children.length > 0 ? (
                      <Router {...props} routes={children} />
                    ) : null}
                  </React.Fragment>
                )}
                {...rest}
              />
            );
          }
        )}
      </Switch>
    </Suspense>
  );
};

Router.propTypes = {
  routes: PropTypes.array.isRequired,
};

export default Router;

import React from "react";
import PropTypes from "prop-types";

function IconClose({ fill, size, testId }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 18 18"
      version="1.1"
      data-testid={testId}
    >
      <defs>
        <clipPath id="clip1">
          <path d="M 17.671875 0.328125 C 18.109375 0.761719 18.109375 1.46875 17.671875 1.902344 L 10.578125 9 L 17.671875 16.097656 C 18.109375 16.53125 18.109375 17.238281 17.671875 17.671875 C 17.238281 18.109375 16.53125 18.109375 16.097656 17.671875 L 9 10.578125 L 1.902344 17.671875 C 1.46875 18.109375 0.761719 18.109375 0.328125 17.671875 C -0.109375 17.238281 -0.109375 16.53125 0.328125 16.097656 L 7.421875 9 L 0.328125 1.902344 C -0.109375 1.46875 -0.109375 0.761719 0.328125 0.328125 C 0.761719 -0.109375 1.46875 -0.109375 1.902344 0.328125 L 9 7.421875 L 16.097656 0.328125 C 16.53125 -0.109375 17.238281 -0.109375 17.671875 0.328125 Z M 17.671875 0.328125 " />
        </clipPath>
      </defs>
      <g id="surface1">
        <g clipPath="url(#clip1)" clipRule="evenodd">
          <rect
            x="0"
            y="0"
            width="18"
            height="18"
            fill={fill}
            fillOpacity={1}
            stroke="none"
            style={{
              WebkitClipPath: "url(#clip1)",
            }}
          />
        </g>
      </g>
    </svg>
  );
}

IconClose.propTypes = {
  fill: PropTypes.string,
  size: PropTypes.number,
  testId: PropTypes.string,
};

IconClose.defaultProps = {
  fill: "#fff",
  size: 16,
  testId: "close-icon",
};

export default IconClose;

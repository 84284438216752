import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import Input from "../../../../Components/Inputs/FormInput";
import Dropdown from "../../../../Components/Inputs/Dropdown";
import FeedbackAlertForm from "./styles";
import SuccessButton from "../../../../Components/Buttons/SuccessButton";
import { submitFeedback } from "../../../../common/utilities/api";
import { sendInterfaceEvent } from "../../../../common/utilities/analytics";

export default function Form({ showSuccessAlert, close }) {
  const [feedbackQueryOption, setFeedbackQueryOption] = useState("-Select");
  const [feedbackScaleOption, setFeedbackScaleOption] = useState("-Select");
  const [feedbackEmail, setFeedbackEmail] = useState("");
  const [feedbackEntered, setFeedbackEntered] = useState("");
  const [emailEntered, setEmailEntered] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [redirectToContact, setRedirectToContact] = useState(false);
  const validateEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  const feedbackOptions = [
    "-Select",
    "Something I like",
    "Something I think could be better",
    "I have a technical issue (you will be redirected to contact us.)",
    "Something else",
  ];

  const feedbackScaleOptions = [
    "-Select",
    "10 - Extremely likely",
    "9",
    "8",
    "7",
    "6",
    "5",
    "4",
    "3",
    "2",
    "1",
    "0 - Not at all likely",
  ];

  const feedbackOptionValue = () => {
    switch (feedbackQueryOption) {
      case "Something I like":
        return "like";
      case "Something I think could be better":
        return "improve";
      case "I have a technical issue (you will be redirected to contact us.)":
        return "issue";
      case "Something else":
        return "other";
      default:
        return null;
    }
  };

  const feedbackRating = () => {
    switch (feedbackScaleOption) {
      case "10 - Extremely likely":
        return 10;
      case "9":
        return 9;
      case "8":
        return 8;
      case "7":
        return 7;
      case "6":
        return 6;
      case "5":
        return 5;
      case "4":
        return 4;
      case "3":
        return 3;
      case "2":
        return 2;
      case "1":
        return 1;
      case "0 - Not at all likely":
        return 0;
      default:
        return null;
    }
  };

  const handleBlur = () => {
    if (feedbackEmail !== "") {
      setEmailEntered(true);
    } else {
      setEmailEntered(false);
    }

    if (validateEmail.test(feedbackEmail)) {
      setEmailEntered(false);
    }
  };

  const submitFormFeedback = async () => {
    sendInterfaceEvent({
      text: "submit",
      module: "feedback"
    });
    setFormSubmitted(true);

    const res = await submitFeedback({
      reason: feedbackOptionValue(),
      rating: feedbackRating(),
      message: feedbackEntered,
      email: feedbackEmail,
    });

    const { data: { code, message } = {} } = res;

    if (code === 0) {
      setFormSubmitted(false);
      showSuccessAlert({
        title: message,
        message: "Thank you for submitting your feedback, we value your input.",
      });
      close();
    }

    return null;
  };

  const updateFeedback = (text) => {
    if (text.length > 500) {
      setFeedbackEntered(text.substr(0, 500));
      return () => {};
    }
    setFeedbackEntered(text);
    return () => {};
  };

  useEffect(() => {
    if (
      feedbackQueryOption ===
      "I have a technical issue (you will be redirected to contact us.)"
    ) {
      setRedirectToContact(true);
      setTimeout(() => {
        close();
      }, 1);
    }

    return () => {};
  }, [feedbackQueryOption]);

  return (
    <>
      {redirectToContact ? <Redirect to="/contact" /> : null}
      <FeedbackAlertForm>
        <label htmlFor="feedback-query-filter">
          What would you like to tell us about today? *
        </label>
        <Dropdown
          testid="feedback-query-filter"
          name="feedback-query-filter"
          options={feedbackOptions}
          disabledOption
          onChange={(e) => setFeedbackQueryOption(e.target.value)}
          value={feedbackQueryOption}
          error={formSubmitted && feedbackQueryOption === "-Select"}
        />

        <label htmlFor="feedback-textarea">
          Please give us your feedback: *
        </label>
        <FeedbackAlertForm.Textarea
          data-testid="feedback-textarea"
          name="feedback-textarea"
          maxLength="500"
          onChange={(e) => updateFeedback(e.target.value)}
          value={feedbackEntered || ""}
          error={formSubmitted && feedbackEntered === ""}
        />

        <p className="small">
          (
          {feedbackEntered && feedbackEntered.length
            ? `${500 - feedbackEntered.length} characters remaining`
            : "max 500 characters"}
          )
        </p>

        <label htmlFor="feedback-scale-filter">
          On a scale of 0-10, how would you rate Fantasy Football and how likely
          are you to recommend us to a friend? *
        </label>
        <Dropdown
          testid="feedback-scale-filter"
          name="feedback-scale-filter"
          options={feedbackScaleOptions}
          disabledOption
          onChange={(e) => setFeedbackScaleOption(e.target.value)}
          value={feedbackScaleOption}
          error={formSubmitted && feedbackScaleOption === "-Select"}
        />

        <label htmlFor="feedback-email">
          Please leave your email address in case we need to respond to your
          feedback:
        </label>
        <Input
          name="feedback-email"
          type="text"
          testid="feedback-email"
          onChange={(e) => setFeedbackEmail(e.target.value)}
          value={feedbackEmail || ""}
          blurEvent={() => handleBlur()}
          errorStyling={emailEntered && !validateEmail.test(feedbackEmail)}
          customError
        />

        <FeedbackAlertForm.Submit>
          <SuccessButton
            type="button"
            clickEvent={() => submitFormFeedback()}
            testid="submit-feedback"
            disabled={
              feedbackQueryOption === "-Select" ||
              feedbackScaleOption === "-Select" ||
              feedbackEntered === "" ||
              (emailEntered && !validateEmail.test(feedbackEmail))
            }
            feedback
          >
            Submit
          </SuccessButton>
        </FeedbackAlertForm.Submit>
      </FeedbackAlertForm>
    </>
  );
}

Form.propTypes = {
  showSuccessAlert: PropTypes.func,
  close: PropTypes.func,
};

Form.defaultProps = {
  showSuccessAlert: () => {},
  close: () => {},
};

import * as api from "../../utilities/api";

export async function loginUser(dispatch, loginPayload) {
  if (window.Android) {
    window.Android.login();
    return () => {};
  }
  try {
    dispatch({ type: "REQUEST_LOGIN" });

    const res = await api.login(loginPayload.token);

    if (res.data) {
      switch (res.data.code) {
        case 0:
          dispatch({
            type: "LOGIN_SUCCESS",
            payload: {
              token: loginPayload.token,
              accessToken: loginPayload.accessToken || "",
              firstName: res.data.firstName,
              supportedTeamId: res.data.supportedTeamId,
              canLinkTo: res.data.canLinkTo,
              fullReg: res.data.fullReg,
              userId: res.data.userId,
            },
          });
          sessionStorage.setItem(
            "currentUser",
            JSON.stringify({
              token: loginPayload.token,
              firstName: res.data.firstName,
              supportedTeamId: res.data.supportedTeamId,
              canLinkTo: res.data.canLinkTo,
              userId: res.data.userId,
            })
          );
          break;

        case 2:
        case 3:
          dispatch({ type: "LOGIN_ERROR", error: res.data.message });
          break;

        default:
          return null;
      }
    }
  } catch (error) {
    dispatch({ type: "LOGIN_ERROR", error });
  }

  return null;
}

export async function logout(dispatch, history) {
  dispatch({ type: "LOGOUT" });
  sessionStorage.removeItem("currentUser");
  sessionStorage.removeItem("userTeams");
  sessionStorage.removeItem("oauth_accessToken");
  sessionStorage.removeItem("oauth_refreshToken");
  sessionStorage.removeItem("oauth_idToken");
  sessionStorage.removeItem("oauth_codeVerifier");
  sessionStorage.removeItem("oauth_state");
  if (history) {
    history.push("/");
    return () => {};
  }
  window.location.href = "/";
  return () => {};
}

import React, { useState, useEffect, useContext } from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import isBetween from "dayjs/plugin/isBetween";
import Container from "../../../../Components/ContentContainer/styles";
import SiteBanner from "./styles";
import { GlobalStateContext } from "../../../../common/contexts/GlobalContext";

dayjs.extend(utc);
dayjs.extend(relativeTime);
dayjs.extend(isBetween);

function DeadlineBanner() {
  const { appState, dispatch } = useContext(GlobalStateContext);
  const { wildcardActive, activeCompetition } = appState;

  const getCountdown = () => {
    if (
      !activeCompetition ||
      (!activeCompetition.deadline && !activeCompetition.wildcard)
    )
      return null;
    let unixDeadline = 0;
    let difference = 0;
    if (
      activeCompetition?.wildcard &&
      activeCompetition.wildcard.opens &&
      activeCompetition.wildcard.closes &&
      dayjs
        .utc()
        .local()
        .isBetween(
          dayjs.utc(activeCompetition.wildcard.opens).local(),
          dayjs.utc(activeCompetition.wildcard.closes).local(),
          "second"
        )
    ) {
      if (!activeCompetition.wildcard.preseason) {
        unixDeadline = dayjs
          .utc(activeCompetition.wildcard.closes)
          .local()
          .valueOf();
      } else {
        unixDeadline = dayjs.utc(activeCompetition.deadline).local().valueOf();
      }
    } else {
      unixDeadline = dayjs.utc(activeCompetition.deadline).local().valueOf();
      // If wildcard is active reset wildcardActive to false
      // due to the window now
      if (wildcardActive) {
        dispatch({ type: "SET_WILDCARD_STATUS", payload: false });
      }
    }

    difference = unixDeadline - dayjs.utc().local().valueOf();

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      timeLeft = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }
    if (
      wildcardActive &&
      timeLeft &&
      Object.keys(timeLeft).every((item) => timeLeft[item] === 0)
    ) {
      dispatch({ type: "SET_WILDCARD_STATUS", payload: false });
      unixDeadline = dayjs.utc(activeCompetition.deadline).local().valueOf();
      difference = unixDeadline - dayjs.utc().local().valueOf();
      if (difference > 0) {
        timeLeft = {
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60),
        };
      } else {
        timeLeft = {
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
        };
      }
    }
    return timeLeft;
  };

  const [deadline, setDeadline] = useState(getCountdown());

  useEffect(() => {
    const timer = setTimeout(() => {
      setDeadline(getCountdown());
      if (activeCompetition.wildcard) {
        if (
          activeCompetition.wildcard.opens &&
          activeCompetition.wildcard.closes &&
          dayjs
            .utc()
            .local()
            .isBetween(
              dayjs.utc(activeCompetition.wildcard.opens).local(),
              dayjs.utc(activeCompetition.wildcard.closes).local(),
              "second",
              []
            )
        ) {
          if (!wildcardActive) {
            dispatch({ type: "SET_WILDCARD_STATUS", payload: true });
          }
        } else {
          dispatch({ type: "SET_WILDCARD_STATUS", payload: false });
        }
      }
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  });

  const generateBannerText = () => {
    if (
      wildcardActive &&
      activeCompetition?.wildcard &&
      !activeCompetition.wildcard.preseason
    ) {
      return `Overhaul Deadline: `;
    }
    if (activeCompetition?.type === "tourn") {
      return `Gameround Deadline: `;
    }
    return `Gameweek Deadline: `;
  };

  if (
    !activeCompetition ||
    (!activeCompetition?.deadline && !activeCompetition?.wildcard) ||
    !deadline
  )
    return null;
  return (
    <SiteBanner
      wildcard={
        wildcardActive &&
        activeCompetition.wildcard &&
        !activeCompetition.wildcard.preseason
      }
      tournamentMode={activeCompetition.type === "tourn"}
    >
      <Container>
        <span className="title">{generateBannerText()}</span>
        <span>
          <span className="number">
            {deadline.days < 10 ? `0${deadline.days}` : deadline.days}
          </span>{" "}
          Days{" "}
          <span className="number">
            {deadline.hours < 10 ? `0${deadline.hours}` : deadline.hours}
          </span>{" "}
          Hours{" "}
          <span className="number">
            {deadline.minutes < 10 ? `0${deadline.minutes}` : deadline.minutes}
          </span>{" "}
          Mins{" "}
          <span className="number">
            {deadline.seconds < 10 ? `0${deadline.seconds}` : deadline.seconds}
          </span>{" "}
          Secs{" "}
        </span>
      </Container>
    </SiteBanner>
  );
}

export default DeadlineBanner;

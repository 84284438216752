import styled from "styled-components";

const SiteHeader = styled.header`
  display: flex;
  flex-direction: column;
  position: ${({ open }) => (!open ? "initial" : "sticky")};
  height: ${({ open }) => (!open ? "auto" : "100%")};
  background: ${({ theme, wildcardActive }) =>
    !wildcardActive
      ? `linear-gradient(-180deg, ${theme.phthaloBlueTwo} 0%, ${theme.oxfordBlueTwo} 100%)`
      : theme.mineShaftGrey};
  overflow: ${({ mobileView }) => (mobileView ? "hidden" : "initial")};
  z-index: ${({ open }) => (!open ? "100" : "101")};
  ${({ openLogoutPanel }) => {
    if (openLogoutPanel) {
      return `
        background-color: rgba(0, 0, 0, 0);
      `;
    }
    return null;
  }}
  @media (min-width: ${({ theme }) => `${theme.largeScreen}px`}) {
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.15);
  }
  > div {
    &:first-child {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      z-index: 1;
      padding: 16px;
      height: 48px;
      @media (min-width: ${({ theme }) => `${theme.largeScreen}px`}) {
        height: auto;
        justify-content: flex-start;
      }
      > div {
        flex: 1;
      }
      .menu-button {
        button {
          display: flex;
          color: inherit;
          img {
            height: 16px;
            width: 16px;
            align-self: center;
          }
        }
        @media (min-width: ${({ theme }) => `${theme.largeScreen}px`}) {
          display: none;
        }
      }
      .logo {
        text-align: center;
        a {
          display: flex;
          justify-content: center;
          @media (min-width: ${({ theme }) => `${theme.largeScreen}px`}) {
            display: inherit;
          }
        }
        img {
          max-width: 85px;
          height: auto;
          align-self: center;
        }
        @media (min-width: ${({ theme }) => `${theme.largeScreen}px`}) {
          text-align: left;
          img {
            max-width: 185px;
          }
        }
      }
      .account {
        display: flex;
        justify-content: flex-end;
        a {
          font-size: 1rem;
          color: ${({ theme }) => theme.white};
        }
      }
      .login-profile-icon {
        width: 16px;
        cursor: pointer;
      }
      .login-link {
        display: flex;
        align-items: center;
        font-weight: 500;
        cursor: pointer;

        span {
          display: none;
          color: ${({ theme }) => theme.white};

          @media (min-width: ${({ theme }) => `${theme.largeScreen}px`}) {
            display: block;
          }
        }
      }
    }
  }
`;

SiteHeader.FirstName = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.white};
  cursor: pointer;
  span {
    font-weight: 500;
  }
`;

SiteHeader.ProfileIcon = styled.img`
  margin: 0 8px 0 0;
  max-height: 16px;
  width: auto;
`;

export default SiteHeader;

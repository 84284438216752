const activeCompetitionId = +localStorage.getItem("activeCompetitionId") || "";

const userTeams = sessionStorage.getItem("userTeams")
  ? JSON.parse(sessionStorage.getItem("userTeams"))
  : [];

export const initialState = {
  activeCompetitionId,
  activeCompetition: {},
  gameSetup: {},
  myTeam: {},
  gameweek: {},
  playerList: {},
  userTeams,
  wildcardActive: false,
  newlyCreatedTeam: false,
  transfersConfirmed: false,
  captainsConfirmed: false,
  ads: [],
  systemMessages: [],
  teamsFetched: false,
};

export const GlobalReducer = (initState, action) => {
  switch (action.type) {
    case "STORE_APPLICATION_STATE": {
      // pull out the competition with the current property
      const currentCompetition = action.payload.competitions.find(
        (competition) => competition.current
      );
      // Check that the active competition id has been set from localStorge
      // so we only overwrite the state if one hasn't been stored previously
      if (!initState.activeCompetitionId) {
        if (currentCompetition) {
          return {
            ...initState,
            activeCompetitionId: currentCompetition.id,
            activeCompetition: currentCompetition,
            gameSetup: action.payload,
          };
        }
      }
      // Search for the competition matching the id from local storage
      const activeCompetition = action.payload.competitions.find(
        (competition) => competition.id === initState.activeCompetitionId
      );
      // If the competition is found, return the state with the competition
      if (activeCompetition) {
        return {
          ...initState,
          gameSetup: action.payload,
          activeCompetition,
          activeCompetitionId: activeCompetition.id,
        };
      }
      // If the competition is not found, return the state with the competition with the current property
      return {
        ...initState,
        gameSetup: action.payload,
        activeCompetition: currentCompetition,
        activeCompetitionId: currentCompetition.id,
      };
    }

    case "SET_ACTIVE_COMPETITION_ID": {
      window.localStorage.setItem("activeCompetitionId", action.payload);
      const activeCompetition = initState.gameSetup.competitions.find(
        (competition) => competition.id === action.payload
      );
      return {
        ...initState,
        activeCompetition,
        activeCompetitionId: action.payload,
      };
    }

    case "STORE_PLAYER_LIST":
      return {
        ...initState,
        playerList: action.payload,
      };

    case "UPDATE_USER_TEAM":
      return {
        ...initState,
        myTeam: action.payload,
      };

    case "UPDATE_TEAM_PREVIOUS_WEEK":
      return {
        ...initState,
        myTeam: {
          ...initState.myTeam,
          players: action.payload.players,
          team: { ...initState.myTeam.team, wkPoints: action.payload.wkPoints },
          transfers: action.payload.transfers,
        },
      };

    case "SET_GAMEWEEK_FIXTURES":
      return {
        ...initState,
        gameweek: { ...initState.gameweek, ...action.payload },
      };

    case "SET_USER_TEAMS":
      return {
        ...initState,
        userTeams: action.payload,
        teamsFetched: true,
      };

    case "SET_FETCHED_TEAMS":
      return {
        ...initState,
        teamsFetched: action.payload,
      };

    case "UPDATE_USER_TEAMNAME": {
      const index = initState.userTeams.findIndex(
        (team) => team.id === action.teamid
      );

      const updatedUserTeams = [...initState.userTeams];

      updatedUserTeams[index].name = action.payload;

      sessionStorage.setItem("userTeams", JSON.stringify(updatedUserTeams));

      return {
        ...initState,
        userTeams: updatedUserTeams,
      };
    }

    case "SET_SUPPORTED_TEAM_ID": {
      sessionStorage.setItem(
        "currentUser",
        JSON.stringify({
          token: JSON.parse(sessionStorage.getItem("currentUser")).token,
          firstName: JSON.parse(sessionStorage.getItem("currentUser"))
            .firstName,
          supportedTeamId: action.payload,
        })
      );
      return {
        ...initState,
      };
    }

    case "SET_WILDCARD_STATUS": {
      return {
        ...initState,
        wildcardActive: action.payload,
      };
    }
    case "SET_NEWLY_CREATED_TEAM": {
      return {
        ...initState,
        newlyCreatedTeam: true,
      };
    }

    case "RESET_NEWLY_CREATED_TEAM": {
      return {
        ...initState,
        newlyCreatedTeam: false,
      };
    }

    case "CAPTAINS_CONFIRMED": {
      return {
        ...initState,
        captainsConfirmed: action.payload,
      };
    }

    case "TRANSFERS_CONFIRMED": {
      return {
        ...initState,
        transfersConfirmed: action.payload,
      };
    }

    case "STORE_SYSTEM_MESSAGES":
      return {
        ...initState,
        systemMessages: action.payload,
      };

    case "STORE_BANNER_ADS":
      return {
        ...initState,
        ads: action.payload,
      };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

import styled from "styled-components";

const AlertWrapper = styled.div`
  background: ${({ theme }) => theme.white};
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.2);
  width: 95%;
  max-width: 800px;
  border-radius: 4px;
  @media (min-width: ${({ theme }) => `${theme.largeScreen}px`}) {
    width: 100%;
  }
  .header {
    display: flex;
    position: relative;
    width: 100%;
    padding: 16px 16px 15px;
    border-bottom: 1px solid ${({ theme }) => theme.platinum};
    h3 {
      font-size: 1.125rem;
      line-height: 1.5;
      font-weight: 500;
      margin: 0 auto;
    }
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 25px;
      width: 25px;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 16px;
      margin: auto;
      &:focus {
        outline: thin solid ${({ theme }) => theme.altoGrey};
      }
      img {
        width: 16px;
        height: 16px;
        align-self: center;
      }
    }
  }
  .message {
    position: relative;
    padding: 15px 16px 16px;
    p {
      font-size: 1rem;
    }
  }
  .footer {
    display: flex;
    justify-content: flex-end;
    padding: 16px;
    border-top: 1px solid ${({ theme }) => theme.platinum};
  }
`;

export default AlertWrapper;

import { rgba } from "polished";
import styled from "styled-components";

export const CompetitionsSelectorContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  background: ${({ theme }) => rgba(theme.primary, 0.25)};
  width: 100%;
  max-width: 1140px;
  margin: auto;
  padding-bottom: 5px;
  overflow-x: auto;
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
  @media (min-width: ${({ theme }) => `${theme.smallScreen}px`}) {
    background: ${({ theme }) => theme.white};
    &:after {
      content: "";
      display: block;
      height: 5px;
      bottom: 0;
      width: 100%;
      position: absolute;
      background: ${({ theme }) => theme.galleryGrey};
    }
  }
  @media (min-width: ${({ theme }) => `${theme.largeScreen}px`}) {
    margin-top: 10px;
  }
`;

export const CompetitionButton = styled.button`
  font-family: "Sky", sans-serif;
  position: relative;
  font-size: 1rem;
  font-weight: 400;
  min-width: ${({ multiComp }) => (multiComp ? "47.5%" : "50%")};
  background-color: ${({ theme }) => theme.white};
  border: none;
  color: ${({ theme }) => theme.primary};
  cursor: pointer;
  padding: 10px;
  text-transform: uppercase;
  &:last-child {
    margin-right: 0;
  }
  &:after {
    content: "";
    display: block;
    height: 5px;
    left: 0;
    bottom: -5px;
    width: 100%;
    position: absolute;
    z-index: 1;
    background: ${({ tournamentMode, theme }) => tournamentMode ? `${rgba(theme.skyFantasyTournamentSecondary, 0.25)}` : `${rgba(theme.primary, 0.25)}`};
  }
  @media (min-width: ${({ theme }) => `${theme.smallScreen}px`}) {
    min-width: 200px;
  }
  ${({ active, theme, tournamentMode }) => {
    if (active) {
      return `
        color: ${
          tournamentMode ? theme.skyFantasyTournamentSecondary : theme.primary
        };
        font-weight: 500;
        &:after {
          background: ${
            tournamentMode ? theme.skyFantasyTournamentSecondary : theme.primary
          };
        };
      }
        `;
    }
    return ``;
  }}
`;

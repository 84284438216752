import React from "react";
import PropTypes from "prop-types";

function IconHome({ size, fill }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={(19 / 20) * size}
      height={size}
      viewBox="0 0 19 20"
    >
      <clipPath id="a-home">
        <path
          transform="matrix(1 0 0 -1 0 20)"
          d="M.5 0v10.602a1 1 0 0 0 .273.687l7.931 8.398a1 1 0 0 0 .727.313h.138a1 1 0 0 0 .727-.313l7.931-8.398a1 1 0 0 0 .273-.687V0h-5c-.5 0-1 .5-1 1v7h-6V1c0-.5-.5-1-1-1h-5z"
          fillRule="evenodd"
        />
      </clipPath>
      <g clipPath="url(#a-home)">
        <clipPath id="b-home">
          <path transform="matrix(1 0 0 -1 0 20)" d="M-23-48h375v1348H-23z" />
        </clipPath>
        <g clipPath="url(#b-home)">
          <path d="M-4.5-5h28v30h-28z" fill={fill} />
        </g>
      </g>
    </svg>
  );
}

IconHome.propTypes = {
  size: PropTypes.number,
  fill: PropTypes.string,
};

IconHome.defaultProps = {
  size: 20,
  fill: "#aaa",
};

export default IconHome;

import styled from "styled-components";
import ErrorBackground from "./assets/Error_Background.png";

const MaintenanceContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0
  right: 0;
  display: flex;
  height: 100%;
  width: 100%;
  background: url(${ErrorBackground}) no-repeat; // Desktop background?
  background-size: cover;
  z-index: 9999;
`;

MaintenanceContainer.Inner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 48px;
  margin: auto;
  color: ${({ theme }) => theme.white};

  svg {
    width: 200px;
    height: 75px;
    margin-bottom: 24px;
  }

  p {
    font-weight: 500;
    text-align: center;
    line-height: 1.2;
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export default MaintenanceContainer;

import React from "react";
import PropTypes from "prop-types";

import Container from "./styles";

function ContentContainer({
  children,
  splitView,
  newsSplitView,
  newsArticleSplitView,
}) {
  if (!children) return null;
  return (
    <Container
      splitView={splitView}
      newsSplitView={newsSplitView}
      newsArticleSplitView={newsArticleSplitView}
    >
      {children}
    </Container>
  );
}

ContentContainer.propTypes = {
  splitView: PropTypes.bool,
  newsSplitView: PropTypes.bool,
  newsArticleSplitView: PropTypes.bool,
  children: PropTypes.node,
};

ContentContainer.defaultProps = {
  splitView: false,
  newsSplitView: false,
  newsArticleSplitView: false,
  children: null,
};

export default ContentContainer;

import styled from "styled-components";

const MenuWrapper = styled.ol`
  top: 40px;
  left: 0px;
  will-change: transform;
  position: absolute;
  z-index: 1000;
  width: 100%;
  min-width: 18rem;
  padding: 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  background-color: ${({ theme }) => theme.oxfordBlueTwo};
  text-align: left;
  list-style: none;
  &:before {
    content: "";
    width: 0;
    height: 0;
    border-width: 0 7px 7px 7px;
    border-style: solid;
    border-color: ${({ theme }) =>
      `transparent transparent ${theme.oxfordBlueTwo} transparent`};
    position: absolute;
    top: -7px;
    left: 29px;
    @media (min-width: ${({ theme }) =>
        `${theme.largeScreen}px`}) and (max-width: ${({ theme }) =>
        `${theme.extraLargeScreen}px`}) {
      left: unset;
      right: 25px;
    }
  }
  > li {
    > a {
      color: ${({ theme }) => theme.white};
      border-bottom-color: ${({ theme }) => theme.white};
      button {
        display: block;
        width: 100%;
        text-align: left;
      }
    }
    button {
      font-size: 1.125rem;
      line-height: 1.5rem;
      padding: 0;
      color: ${({ theme }) => theme.white};

      &:hover {
        font-weight: 500;
      }
    }
  }

  @media (min-width: ${({ theme }) => `${theme.largeScreen}px`}) {
    width: auto;
    top: 37px;
    right: 0;
    left: initial;
    background-clip: padding-box;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.15);
  }

  @media (min-width: ${({ theme }) => `${theme.extraLargeScreen}px`}) {
    right: initial;
    left: 0;
  }

  .external-link {
    a {
      display: block;
      font-size: 1.125rem;
      line-height: 1.5rem;
      color: ${({ theme }) => theme.white};
      text-decoration: none;
      padding: 8px 16px;
      font-weight: 400;

      &:hover {
        font-weight: 500;
      }
    }
  }
`;

export default MenuWrapper;

import axios from "axios";

const ROOT_API = process.env.SKY_BET_FANTASY_API_ENDPOINT;
const AUTH_API = process.env.SKY_BET_FANTASY_OAUTH_ENVIRONMENT;
const API_KEY = process.env.SKY_BET_FANTASY_API_KEY;

const getQueryFromPeriod = (period, week, month, compId) => {
  switch (period) {
    case "week":
      return `?weekId=${week}&compId=${compId}`;
    case "month":
      return `?monthId=${month}&compId=${compId}`;
    default:
      return `?compId=${compId}`;
  }
};

/* ----------------------- */
/* Application State
/* ----------------------- */

export const getApplicationState = (platform = "web") => {
  return axios({
    url: `${ROOT_API}/multistate/${platform}`,
    method: "get",
    headers: { "x-api-key": `${API_KEY}`, "Content-Type": "application/json" },
  });
};

/* ----------------------- */
/* Session
/* ----------------------- */

export const login = (idToken) => {
  return axios({
    url: `${ROOT_API}/authenticate`,
    method: "post",
    headers: {
      "x-api-key": `${API_KEY}`,
      "Content-Type": "application/json",
      Authorization: `${idToken}`,
    },
  }).catch((error) => error);
};

export const validateToken = (payload) => {
  return axios({
    url: `https://${AUTH_API}.skybetservices.com/openid/v1/token_info`,
    method: "post",
    headers: { "x-api-key": `${API_KEY}`, "Content-Type": "application/json" },
    data: payload,
  });
};

/* ----------------------- */
/* Player List
/* ----------------------- */

export const getPlayerList = (compId) => {
  return axios({
    url: `${ROOT_API}/players?compId=${compId}`,
    method: "get",
    headers: { "x-api-key": `${API_KEY}`, "Content-Type": "application/json" },
  });
};

/* ----------------------- */
/* Pitch List
/* ----------------------- */

export const getPlayerDetails = (playerId, compId) => {
  return axios({
    url: `${ROOT_API}/player/${playerId}?compId=${compId}`,
    method: "get",
    headers: { "x-api-key": `${API_KEY}`, "Content-Type": "application/json" },
  });
};

/* ----------------------- */
/* Fixture List
/* ----------------------- */

export const getFixtureList = (id, compId) => {
  return axios({
    url: `${ROOT_API}/fixtures?weekId=${id}&compId=${compId}`,
    method: "get",
    headers: { "x-api-key": `${API_KEY}`, "Content-Type": "application/json" },
  });
};

/* ----------------------- */
/* Fixture
/* ----------------------- */

export const getFixture = (id, compId) => {
  return axios({
    url: `${ROOT_API}/fixture/${id}?compId=${compId}`,
    method: "get",
    headers: { "x-api-key": `${API_KEY}`, "Content-Type": "application/json" },
  });
};

/* ----------------------- */
/* Get an Autopick Team
/* ----------------------- */

export const getAutopickTeam = (compId) => {
  return axios({
    url: `${ROOT_API}/autopick?compId=${compId}`,
    method: "get",
    headers: {
      "x-api-key": `${API_KEY}`,
      "Content-Type": "application/json",
    },
  });
};

/* ----------------------- */
/* User's Team
/* ----------------------- */

export const getUserTeam = (token, id, week = "", compId) => {
  return axios({
    url: `${ROOT_API}/teams/${id}?weekId=${week}&compId=${compId}`,
    method: "get",
    headers: {
      "x-api-key": `${API_KEY}`,
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

/* ----------------------- */
/* Submit User Team
/* ----------------------- */
export const submitUserTeam = (token, payload) => {
  return axios({
    url: `${ROOT_API}/team`,
    method: "post",
    headers: {
      "x-api-key": `${API_KEY}`,
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: payload,
  }).catch((error) => error);
};

/* ----------------------- */
/* Update User Team
/* ----------------------- */
export const updateUserTeam = (token, teamId, payload) => {
  return axios({
    url: `${ROOT_API}/team/${teamId}`,
    method: "put",
    headers: {
      "x-api-key": `${API_KEY}`,
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: payload,
  }).catch((error) => error);
};

/* ----------------------- */
/* Get User Teams
/* ----------------------- */
export const getUserTeams = (token, compId) => {
  return axios({
    url: `${ROOT_API}/teams?compId=${compId}`,
    method: "get",
    headers: {
      "x-api-key": `${API_KEY}`,
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

/* ----------------------- */
/* Get Another User's Team
/* ----------------------- */

export const getOtherTeam = (token, id, compId) => {
  return axios({
    url: `${ROOT_API}/otherteams/${id}?compId=${compId}`,
    method: "get",
    headers: {
      "x-api-key": `${API_KEY}`,
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

/* ----------------------- */
/* Get Leaderboard
/* ----------------------- */
export const getLeaderboard = (period, week, month, compId) => {
  const query = getQueryFromPeriod(period, week, month, compId);
  return axios({
    url: `${ROOT_API}/leaderboard/${period}${query}`,
    method: "get",
    headers: {
      "x-api-key": `${API_KEY}`,
      "Content-Type": "application/json",
    },
  });
};

/* ----------------------- */
/* Get Leaderboard My Position
/* ----------------------- */
export const getLeaderboardMyPosition = (
  period,
  week,
  month,
  token,
  compId
) => {
  const query = getQueryFromPeriod(period, week, month, compId);
  return axios({
    url: `${ROOT_API}/leaderboardPos/${period}${query}`,
    method: "get",
    headers: {
      "x-api-key": `${API_KEY}`,
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

/* ----------------------- */
/* Get Player Stats
/* ----------------------- */
export const getPlayerStats = (period, week, month, compId) => {
  const query = getQueryFromPeriod(period, week, month, compId);

  return axios({
    url: `${ROOT_API}/stats/${period}${query}`,
    method: "get",
    headers: {
      "x-api-key": `${API_KEY}`,
      "Content-Type": "application/json",
    },
  });
};

/* ----------------------- */
/* Get Stats for Top 5 Performing Players in different categories
/* ----------------------- */
export const getTopFivePlayers = (compId) => {
  return axios({
    url: `${ROOT_API}/topfive?compId=${compId}`,
    method: "get",
    headers: {
      "x-api-key": `${API_KEY}`,
      "Content-Type": "application/json",
    },
  });
};

/* ----------------------- */
/* Get Stats for Team of the Week
/* ----------------------- */
export const getTeamOfTheWeek = (week, compId) => {
  return axios({
    url: `${ROOT_API}/topteam/week?weekId=${week}&compId=${compId}`,
    method: "get",
    headers: {
      "x-api-key": `${API_KEY}`,
      "Content-Type": "application/json",
    },
  });
};

/* ----------------------- */
/* Get Stats League Table
/* ----------------------- */
export const getLeagueTable = (compId) => {
  return axios({
    url: `${ROOT_API}/table?compId=${compId}`,
    method: "get",
    headers: {
      "x-api-key": `${API_KEY}`,
      "Content-Type": "application/json",
    },
  });
};

/* ----------------------- */
/* Create Mini League
/* ----------------------- */
export const createMiniLeague = (token, payload) => {
  return axios({
    url: `${ROOT_API}/minileagues`,
    method: "post",
    headers: {
      "x-api-key": `${API_KEY}`,
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: payload,
  }).catch((error) => error);
};

/* ----------------------- */
/* Get Mini League Pundits
/* ----------------------- */
export const getMiniLeaguePundits = (compId) => {
  return axios({
    url: `${ROOT_API}/pundits?compId=${compId}`,
    method: "get",
    headers: {
      "x-api-key": `${API_KEY}`,
      "Content-Type": "application/json",
    },
  });
};

/* ----------------------- */
/* Get Mini League Public Leagues
/* ----------------------- */
export const getPublicMiniLeagues = (compId) => {
  return axios({
    url: `${ROOT_API}/minileagues/public?compId=${compId}`,
    method: "get",
    headers: {
      "x-api-key": `${API_KEY}`,
      "Content-Type": "application/json",
    },
  });
};

/* ----------------------- */
/* Join Mini League
/* ----------------------- */
export const joinMiniLeague = (token, payload) => {
  return axios({
    url: `${ROOT_API}/minileagues/members`,
    method: "post",
    headers: {
      "x-api-key": `${API_KEY}`,
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: payload,
  }).catch((error) => error);
};

/* ----------------------- */
/* Get Mini Leagues
/* ----------------------- */
export const getMiniLeagues = (token, compId) => {
  return axios({
    url: `${ROOT_API}/minileagues?compId=${compId}`,
    method: "get",
    headers: {
      "x-api-key": `${API_KEY}`,
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

/* ----------------------- */
/* Get Past Mini Leagues
/* ----------------------- */
export const getPastMiniLeagues = (token, compId) => {
  return axios({
    url: `${ROOT_API}/minileagues/past?compId=${compId}`,
    method: "get",
    headers: {
      "x-api-key": `${API_KEY}`,
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

/* ----------------------- */
/* Restart a Mini League
/* ----------------------- */
export const restartMiniLeague = (token, payload) => {
  return axios({
    url: `${ROOT_API}/minileagues/restart`,
    method: "post",
    headers: {
      "x-api-key": `${API_KEY}`,
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: payload,
  }).catch((error) => error);
};

/* ----------------------- */
/* Get Mini League Standings
/* ----------------------- */
export const getMiniLeagueStandings = (
  token,
  pin,
  period,
  week,
  month,
  page,
  compId
) => {
  const setParameters = () => {
    switch (true) {
      case period === "week":
        return `?weekId=${week}&compId=${compId}${
          page !== undefined ? `&page=${page}` : ""
        }`;

      case period === "month":
        return `?monthId=${month}&compId=${compId}${
          page !== undefined ? `&page=${page}` : ""
        }`;

      case period === "season" && page !== undefined:
        return `?page=${page}&compId=${compId}`;

      default:
        return `?compId=${compId}`;
    }
  };

  return axios({
    url: `${ROOT_API}/minileagueStandings/${pin}/${period}${setParameters()}`,
    method: "get",
    headers: {
      "x-api-key": `${API_KEY}`,
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

/* ----------------------- */
/* Get Mini League User Standings
/* ----------------------- */
export const getMiniLeagueUserStandings = (
  token,
  pin,
  period,
  week,
  month,
  compId
) => {
  const query = getQueryFromPeriod(period, week, month, compId);
  return axios({
    url: `${ROOT_API}/minileaguePos/${pin}/${period}${query}`,
    method: "get",
    headers: {
      "x-api-key": `${API_KEY}`,
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

/* ----------------------- */
/* Get Private League
/* ----------------------- */

export const getPrivateLeague = (token, pin, compId) => {
  return axios({
    url: `${ROOT_API}/minileagues/private/${pin}?compId=${compId}`,
    method: "get",
    headers: {
      "x-api-key": `${API_KEY}`,
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

/* ----------------------- */
/* Search For Public Mini Leagues
/* ----------------------- */

export const searchPublicLeagues = (
  entryFee,
  prizeSplit,
  maxTeams,
  canLinkToBet,
  compId
) => {
  const setParameters = () => {
    switch (true) {
      case entryFee >= 0 &&
        prizeSplit === "" &&
        maxTeams === "" &&
        canLinkToBet:
        return `?entryFee=${entryFee}&compId=${compId}`;

      case prizeSplit >= 0 &&
        entryFee === "" &&
        maxTeams === "" &&
        canLinkToBet:
        return `?prizeSplit=${prizeSplit}&compId=${compId}`;

      case maxTeams >= 0 &&
        entryFee === "" &&
        prizeSplit === "" &&
        canLinkToBet:
        return `?maxTeams=${maxTeams}&compId=${compId}`;

      case entryFee >= 0 && maxTeams >= 0 && prizeSplit === "" && canLinkToBet:
        return `?entryFee=${entryFee}&maxTeams=${maxTeams}&compId=${compId}`;

      case entryFee > 0 && prizeSplit >= 0 && maxTeams === "" && canLinkToBet:
        return `?entryFee=${entryFee}&prizeSplit=${prizeSplit}&compId=${compId}`;

      case entryFee > 0 && prizeSplit >= 0 && maxTeams >= 0 && canLinkToBet:
        return `?entryFee=${entryFee}&prizeSplit=${prizeSplit}&maxTeams=${maxTeams}&compId=${compId}`;

      case maxTeams >= 0 &&
        entryFee === "" &&
        prizeSplit === "" &&
        !canLinkToBet:
        return `?maxTeams=${maxTeams}&entryFee=0&compId=${compId}`;

      default:
        return `?compId=${compId}`;
    }
  };

  return axios({
    url: `${ROOT_API}/minileagues/search${setParameters()}`,
    method: "get",
    headers: {
      "x-api-key": `${API_KEY}`,
      "Content-Type": "application/json",
    },
  });
};

/* ----------------------- */
/* Transfers
/* ----------------------- */
export const submitTransfers = (token, payload) => {
  return axios({
    url: `${ROOT_API}/transfers`,
    method: "post",
    headers: {
      "x-api-key": `${API_KEY}`,
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: payload,
  }).catch((error) => error);
};

/* ----------------------- */
/* Get Supported Teams
/* ----------------------- */

export const getSupportedTeams = () => {
  return axios({
    url: `${ROOT_API}/supportedTeams`,
    method: "get",
    headers: {
      "x-api-key": `${API_KEY}`,
      "Content-Type": "application/json",
    },
  });
};

/* ----------------------- */
/* Submit Captains
/* ----------------------- */
export const submitCaptains = (token, payload) => {
  return axios({
    url: `${ROOT_API}/captains`,
    method: "post",
    headers: {
      "x-api-key": `${API_KEY}`,
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: payload,
  }).catch((error) => error);
};

/* ----------------------- */
/* Get Cross Sell Products
/* ----------------------- */

export const getCrossSellProducts = () => {
  return axios({
    url: `${ROOT_API}/products`,
    method: "get",
    headers: {
      "x-api-key": `${API_KEY}`,
      "Content-Type": "application/json",
    },
  }).catch((error) => error);
};

/* ----------------------- */
/* Submit Feedback
/* ----------------------- */
export const submitFeedback = (payload) => {
  return axios({
    url: `${ROOT_API}/feedback`,
    method: "post",
    headers: {
      "x-api-key": `${API_KEY}`,
      "Content-Type": "application/json",
    },
    data: payload,
  }).catch((error) => error);
};

/* ----------------------- */
/* Get System Messages
/* ----------------------- */

export const getSystemMessages = () => {
  return axios({
    url: `${ROOT_API}/adverts`,
    method: "get",
    headers: {
      "x-api-key": `${API_KEY}`,
      "Content-Type": "application/json",
    },
  }).catch((error) => error);
};

import useMobileDetect from "use-mobile-detect-hook";

/* eslint-disable */
function activateGTM() {
  if (!window.dataLayer.find(element => element['gtm.start'])) {
    (function(w,d,s,l,i){w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-57TQ96RL');
  }
}
/* eslint-enable */

function activateOneTrust() {
  if (!window.dataLayer.find(element => element.OnetrustActiveGroups)) {
    const script = document.createElement('script');
    script.setAttribute("src", "https://static.skybetservices.com/tracking-consent/tracking-consent.js");
    script.setAttribute("type", "text/javascript");
    script.setAttribute("data-domain-script", "9818e737-64e9-4e77-a5d2-8f46125e1162");
    document.getElementsByTagName('head')[0].appendChild(script);
  }
}

export function sendEvent(name, params) {
  if (window?.AnalyticsWebInterface?.logEvent) {
    window.AnalyticsWebInterface.logEvent(name, JSON.stringify(params));
  }
  else {
    window.dataLayer.push({
      event: name,
      ...params
    });
  }
}

export function sendNavigationEvent(data) {
  if (data.text && data.module) {
    sendEvent("navigation", {
      element_text: data.text.toLowerCase(),
      action: "navigated to",
      module: data.module,
      position: data.position || "null",
      module_display_order: data.order || "null",
      destination_url: data.url || "null",
      type: "null",
      event_context: data.context || "null",
    });
  }
}

export function sendInterfaceEvent(data) {
  if (data.text && data.module) {
    sendEvent("interface", {
      element_text: data.text.toLowerCase(),
      action: "clicked",
      module: data.module,
      event_context: data.context || "null",
    });
  }
}

export function sendMetaData(userId, fullReg) {
  const detectMobile = useMobileDetect();
  const search = sessionStorage.getItem("oauth_loginRedirectSearch") || window.location.search;
  const searchParams = new URLSearchParams(search);

  let orientationStr = "desktop";
  if (!detectMobile.isDesktop()) {
    if (window?.screen?.orientation?.type) {
      orientationStr = window.screen.orientation.type.startsWith("portrait") ? "portrait" : "landscape";
    }
  }

  const params = {
    event: 'metaData',
    account_balance: "null",
    aff_id: "null",
    bid: "null",
    brand: "sbg",
    btag: "null",
    city: "null",
    clkid: "null",
    connection_type: "null",
    context: "fantasyfootball",
    country: "null",
    currency: "null",
    efid: "null",
    jurisdiction: "uki",
    language: "en_en",
    locale: "en_gb",
    mi_ign: "null",
    mi_u: "null",
    orientation: orientationStr,
    promo_code: "null",
    pi: "null",
    pid: "null",
    product: "web",
    product_theme: "null",
    rfr: "null",
    sid: "null",
    sub_id: "null",
    ttp: "null",
    vertical: "sports",
  }

  if (searchParams) {
    params.btag = searchParams.get('btag') || "null";
    params.mi_ign = searchParams.get('mi_ign') || "null";
    params.mi_u = searchParams.get('mi_u') || "null";
    params.promo_code = searchParams.get('sba_promo') || "null";
    params.rfr = searchParams.get('aff') || "null";
    params.sub_id = searchParams.get('offer') || "null";
  }

  if (userId) {
    params.account_id = userId;    
    params.login_status = "logged in";
    params.reg_status = fullReg ? 'full reg' : 'part reg';
    params.user_id = userId;
  }
  else {
    params.account_id = '123456';    
    params.login_status = "logged out";
    params.reg_status = "null";
    params.user_id = null;
  }

  sessionStorage.removeItem("oauth_loginRedirectSearch");
  window.dataLayer.push(params);
  activateGTM();
  activateOneTrust();
};

import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import ContentContainer from "../../../Components/ContentContainer";
import NavLink from "../Components/NavLink";
import NavBar from "./styles";
import { GlobalStateContext } from "../../../common/contexts/GlobalContext";
import { useAuthState } from "../../../common/contexts/AuthContext";
import MoreMenu from "./Components/MoreMenu";
import ExternalLink from "../../../Components/Links/ExternalLink";
import useCanBet from "../../../common/custom_hooks/canBet";
import theme from "../../../Pages/theme";
import useWindowSize from "../../../common/custom_hooks/windowSize";

const SiteNav = ({ openNav, openFeedbackAlert }) => {
  const { appState } = useContext(GlobalStateContext);
  const { userTeams, wildcardActive, gameSetup, activeCompetition } = appState;
  const { token, products } = useAuthState();
  const { pathname } = useLocation();
  const desktopView = useWindowSize(theme.largeScreen);
  const [toggleMoreMenu, setToggleMoreMenu] = useState(false);
  const canBet = useCanBet(gameSetup.bettingDisabled);

  const CDN_PATH = process.env.SKY_BET_FANTASY_CDN_ENDPOINT;

  const generateMyTeamLink = () => {
    if (token && userTeams && userTeams.length > 0) {
      return `/my-team/${userTeams[0].id}`;
    }
    if (!token) {
      return "/my-team";
    }
    return "/team-select";
  };

  const generateLink = (name) => {
    if (!products || !products.length) return null;
    const linkTo = products.find((product) => product.name === name);
    if (!linkTo) return null;
    if (token) {
      return linkTo.loggedInLink;
    }
    return linkTo.loggedOutLink;
  };

  const openMoreMenu = () => {
    setToggleMoreMenu(!toggleMoreMenu);
  };

  return (
    <NavBar
      open={openNav}
      wildcardActive={
        wildcardActive &&
        activeCompetition.wildcard &&
        !activeCompetition.wildcard.preseason
      }
      data-testid="burger-menu"
      moreMenuOpen={toggleMoreMenu}
    >
      <ContentContainer>
        <ul>
          <li data-testid="header-nav-home">
            <NavLink to="/" text="Home" module="header" position="1" module_display_order="2">
              Home
            </NavLink>
          </li>
          <li data-testid="header-nav-my-team">
            <NavLink to={generateMyTeamLink()} text="My Team" module="header" position="2" module_display_order="2">
              My Team
            </NavLink>
          </li>
          <li
            data-testid="header-nav-leagues"
            className={
              token && userTeams && userTeams.length === 0 ? "inactive" : ""
            }
          >
            <NavLink
              to={
                token && userTeams && userTeams.length === 0
                  ? "/team-select"
                  : "/leagues"
              }
              text="Leagues"
              className={
                userTeams &&
                userTeams.length === 0 &&
                pathname === "/team-select"
                  ? "inactive"
                  : ""
              }
              module="header" 
              position="3" 
              module_display_order="2"
            >
              Leagues
            </NavLink>
          </li>
          <li data-testid="header-leaderboard">
            <NavLink to="/leaderboard" text="Leaderboard" module="header" position="4" module_display_order="2">
              Leaderboard
            </NavLink>
          </li>
          <li data-testid="header-fixtures">
            <NavLink to="/fixtures" text="Fixtures" module="header" position="5" module_display_order="2">
              Fixtures
            </NavLink>
          </li>
          <li data-testid="header-nav-stats">
            <NavLink to="/stats" text="Stats" module="header" position="6" module_display_order="2">
              Stats
            </NavLink>
          </li>          

          {canBet ? (
            <li data-testid="header-nav-bet" className="external">
              <ExternalLink 
                href={generateLink("skybet")} 
                text="Bet" 
                module="header" 
                position="7" 
                module_display_order="2"
                event_context="cross sell">
                Bet
              </ExternalLink>
            </li>
          ) : null}
          <li
            role="presentation"
            data-testid="header-nav-more-menu"
            className="more-menu"
            onClick={() => openMoreMenu()}
          >
            Help
            {toggleMoreMenu ? (
              <MoreMenu
                toggleMoreMenu={toggleMoreMenu}
                setToggleMoreMenu={setToggleMoreMenu}
                openFeedbackAlert={openFeedbackAlert}
                generateLink={generateLink}
                desktopView={desktopView}
                wildcardActive={
                  wildcardActive &&
                  activeCompetition.wildcard &&
                  !activeCompetition.wildcard.preseason
                }
              />
            ) : null}
            {!desktopView && !toggleMoreMenu && (
              <ul className="product-bar">
                <li data-testid="header-nav-super-6" className="external-link">
                  <ExternalLink 
                    href={generateLink("super6")} 
                    module="header" 
                    position="1" 
                    module_display_order="2" 
                    event_context="cross sell"
                    text="super6">
                    <img
                      src={`${CDN_PATH}/products/super6_alt.png`}
                      alt="Super6"
                    />
                  </ExternalLink>
                </li>
                <li data-testid="header-nav-itv7" className="external-link">
                  <ExternalLink 
                    href={generateLink("itv7")} 
                    module="header" 
                    position="2" 
                    module_display_order="2" 
                    event_context="cross sell"
                    text="itv7"
                  >
                    <img src={`${CDN_PATH}/products/itv7.png`} alt="itv7" />
                  </ExternalLink>
                </li>
                <li
                  data-testid="header-nav-sporting-life"
                  className="external-link"
                >
                  <ExternalLink 
                    href={generateLink("sportinglife")} 
                    module="header" 
                    position="3" 
                    module_display_order="2" 
                    event_context="cross sell"
                    text="sporting life"
                  >
                    <img
                      src={`${CDN_PATH}/assets/sportinglife.png`}
                      alt="Sportinglife"
                    />
                  </ExternalLink>
                </li>
              </ul>
            )}
          </li>
        </ul>
      </ContentContainer>
    </NavBar>
  );
};

SiteNav.propTypes = {
  openNav: PropTypes.bool,
  openFeedbackAlert: PropTypes.func,
};

SiteNav.defaultProps = {
  openNav: false,
  openFeedbackAlert: () => {},
};

export default SiteNav;

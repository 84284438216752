import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import AlertWrapper from "./styled";
import GeneralButton from "../Buttons/GeneralButton";
import SuccessButton from "../Buttons/SuccessButton";
import IconClose from "../../common/assets/images/icon_close.png";
import {
  authorizeCheck,
  requestNewAccessToken,
} from "../../common/utilities/authHandler";
import { useAuthDispatch } from "../../common/contexts/AuthContext";

function Alert({
  error,
  setError,
  alertCloseType,
  goToMyAccountDeposit,
  goToMyAccountUpgrade,
}) {
  const AlertRef = useRef();
  const authDispatch = useAuthDispatch();
  const closeError = () => {
    setError(null);
    if (alertCloseType === "deposit") {
      if (window.Android) {
        window.Android.deposit();
        return () => {};
      }
      goToMyAccountDeposit();
    }
    if (alertCloseType === "upgrade") {
      if (window.Android) {
        window.Android.upgrade();
        return () => {};
      }
      goToMyAccountUpgrade();
    }
    if (error && error.code === 4) {
      if (window.Android) {
        window.Android.tokenExpired();
        return () => {};
      }
      if (sessionStorage.getItem("oauth_refreshToken")) {
        requestNewAccessToken(authDispatch);
        return () => {};
      }
      authorizeCheck();
    }
    return () => {};
  };
  const handleClick = (e) => {
    if (AlertRef.current.contains(e.target)) {
      return;
    }
    closeError();
  };
  useEffect(() => {
    window.addEventListener("click", handleClick);
    return () => window.removeEventListener("click", handleClick);
  }, []);

  useEffect(() => {
    if (error.code && error.code === 4) {
      closeError();
    }
    return () => {};
  }, [error]);

  const generateTitle = (code) => {
    switch (code) {
      case 0:
        return "Sky Sports Fantasy Football";
      case 3:
        return "Not Found";
      default:
        return "Sky Sports Fantasy Football";
    }
  };

  if (error.code && error.code === 4) return null;

  return (
    <AlertWrapper ref={AlertRef} data-testid="alert">
      <div className="header">
        <h3>{error.code > -1 ? generateTitle(error.code) : error.title}</h3>
        <GeneralButton type="button" clickEvent={closeError}>
          <img src={IconClose} alt="Close Message" className="close" />
        </GeneralButton>
      </div>
      <div className="message">
        <p>{error.message}</p>
      </div>
      <div className="footer">
        <SuccessButton type="button" clickEvent={closeError}>
          Ok
        </SuccessButton>
      </div>
    </AlertWrapper>
  );
}

Alert.propTypes = {
  error: PropTypes.shape({
    code: PropTypes.number,
    title: PropTypes.string,
    message: PropTypes.string,
  }),
  setError: PropTypes.func,
  alertCloseType: PropTypes.string,
  goToMyAccountDeposit: PropTypes.func,
  goToMyAccountUpgrade: PropTypes.func,
};

Alert.defaultProps = {
  error: {
    code: -1,
    title: "",
    message: "",
  },
  setError: () => {},
  alertCloseType: "",
  goToMyAccountDeposit: () => {},
  goToMyAccountUpgrade: () => {},
};

export default Alert;

import React, { useEffect } from "react";
import GamesNav from "./styles";
import ContentContainer from "../ContentContainer";
import {
  useAuthDispatch,
  useAuthState,
} from "../../common/contexts/AuthContext";
import { getCrossSellProducts } from "../../common/utilities/api";
import { sendNavigationEvent } from "../../common/utilities/analytics";

function CrossSellNav() {
  const authDispatch = useAuthDispatch();
  const { token, products, canLinkTo } = useAuthState();

  const CDN_PATH = process.env.SKY_BET_FANTASY_CDN_ENDPOINT;

  const navigateToProduct = (game, position) => {

    sendNavigationEvent({
      text: game.name,
      module: "cross sell bar",
      context: "cross sell",
      order: "1",
      position: `${position}`,
      url: game.loggedInLink
    });

    // add onetrust cookie consent string to URL
    const url = game.loggedInLink;
    if (window.Android) {
      window.Android.launchCrossSell(url);
      return () => {};
    }
    window.open(url, "_blank").focus();
    return () => {};
  };

  const fetchCrossSellProducts = async () => {
    await getCrossSellProducts().then((response) => {
      const { data } = response;
      if (data.code === 0) {
        authDispatch({
          type: "SET_CROSS_SELL_PRODUCTS",
          payload: data.products,
        });
      }
      return () => {};
    });
    return () => {};
  };

  useEffect(() => {
    if (token && products.length < 1) {
      fetchCrossSellProducts();
    }
    return () => {};
  }, [token]);

  if (!token || products.length < 1) return null;

  return (
    <GamesNav data-testid="cross-sell-nav">
      <ContentContainer>
        {products
          .filter(
            (product) =>
              product.mandatory === 1 ||
              canLinkTo.indexOf(product.shortName) > -1
          )
          .map((game, index) => (
            <GamesNav.Button
              key={game.name}
              onClick={() => navigateToProduct(game, index+1)}
            >
              <img
                src={`${CDN_PATH}/products/${game.name}.png`}
                alt={game.name}
              />
            </GamesNav.Button>
          ))}
      </ContentContainer>
    </GamesNav>
  );
}

export default CrossSellNav;

import { useEffect, useState } from "react";
import { useAuthState } from "../contexts/AuthContext";

function useCanBet(bettingDisabled, cashLeagueCheck = false) {
  const { canLinkTo } = useAuthState();
  const [canBet, setCanBet] = useState();
  function updateBettingStatus() {
    if (
      bettingDisabled === 1 ||
      (bettingDisabled === 0 &&
        canLinkTo.length >= 0 &&
        !canLinkTo.includes("bet")) ||
      (cashLeagueCheck &&
        canLinkTo.length >= 0 &&
        (!canLinkTo.includes("bet") ||
          !canLinkTo.includes("poker") ||
          !canLinkTo.includes("bingo") ||
          !canLinkTo.includes("casino") ||
          !canLinkTo.includes("vegas")))
    ) {
      setCanBet(false);
      return () => {};
    }
    setCanBet(true);
    return () => {};
  }
  useEffect(() => {
    updateBettingStatus();
    return () => {};
  }, [bettingDisabled, canLinkTo]);

  return canBet;
}

export default useCanBet;

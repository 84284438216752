import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import GlobalStyle from "./styles";
import theme from "../theme";
import routes from "../routes";
import Header from "../../Modules/Header";
import Router from "../../Components/Router";
import FlattenRoutes from "../../common/utilities/flattenRoutes";
import RouteContext from "../../common/contexts/RouteContext";
import { GlobalProvider } from "../../common/contexts/GlobalContext";
import { AuthProvider } from "../../common/contexts/AuthContext";
import Footer from "../../Modules/Footer";
import AuthWrapper from "../../Components/AuthWrapper";
import MaintenanceTakeoverWrapper from "../../Components/MaintenanceTakeoverWrapper";

const App = () => {
  const flattenedRoutes = FlattenRoutes(routes);
  const iOSView = window.location.search && window.location.search.startsWith("?ios");

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <GlobalProvider>
        <MaintenanceTakeoverWrapper>
          <AuthProvider>
            <RouteContext.Provider value={flattenedRoutes}>
              <BrowserRouter>
                <AuthWrapper>
                  <div className="app" data-testid="app">
                    {!iOSView ? <Header routes={routes} /> : null}
                    <Router routes={routes} />
                    {!iOSView ? <Footer routes={routes} /> : null}
                  </div>
                </AuthWrapper>
              </BrowserRouter>
            </RouteContext.Provider>
          </AuthProvider>
        </MaintenanceTakeoverWrapper>
      </GlobalProvider>
    </ThemeProvider>
  );
};

export default App;

import styled from "styled-components";

const NavBar = styled.nav`
  flex: 1 1 auto;
  padding: 0;
  width: 100vw;
  height: ${({ open }) => (!open ? "0" : "100%")};
  background-color: ${({ theme }) => theme.white};
  transform: ${({ open }) => (!open ? "translateX(100%)" : "translateX(0)")};
  transition: transform 0.3s ease-in-out;
  overflow-y: auto;
  @media (min-width: ${({ theme }) => `${theme.largeScreen}px`}) {
    display: flex;
    align-items: center;
    position: static;
    height: 56px;
    transform: none;
    overflow-y: unset;
  }
  ul {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0;
    padding: 0;
    > li {
      list-style: none;
      color: ${({ theme }) => theme.primary};
      > a {
        margin-right: 16px;
        color: ${({ theme }) => theme.primary};
        border-color: ${({ theme }) => theme.primary};
        button {
          font-size: 1.125rem;
          line-height: 1.5rem;
          color: ${({ theme }) => theme.white};
          padding: 0;

          &:hover {
            font-weight: 500;
          }
        }
      }
      @media (min-width: ${({ theme }) => `${theme.largeScreen}px`}) {
        padding: 0;
        a {
          padding: 14px 16px;
          text-align: center;
        }
      }
    }
    @media (min-width: ${({ theme }) => `${theme.largeScreen}px`}) {
      flex-direction: row;
      align-items: center;
    }
  }
  .external {
    a {
      font-size: 1.125rem;
      line-height: 1.5rem;
      width: 100%;
      display: block;
      text-decoration: none;
      padding: 8px 16px;
      color: ${({ theme }) => theme.primary};
      &:hover {
        font-weight: 500;
      }
    }
  }
  .inactive {
    border-color: ${({ theme }) => theme.white};
    a {
      font-weight: 400;
      text-decoration: none;

      &:hover {
        font-weight: 500;
        text-decoration: none;
      }
    }
  }
  .more-menu {
    font-size: 1.125rem;
    line-height: 1.5rem;
    position: relative;
    cursor: pointer;
    padding: 8px 16px;

    @media (min-width: ${({ theme }) => `${theme.largeScreen}px`}) {
      padding: 0 16px;
      a {
        padding: 14px 16px;
        text-align: left;
      }
    }

    &:hover {
      font-weight: 500;
    }
  }
  .product-bar {
    display: flex;
    flex-direction: row;
    background: ${({ theme }) => theme.white};
    padding: ${({ moreMenuOpen }) => (moreMenuOpen ? "0 16px" : "0")};
    img {
      width: auto;
      max-height: 17px;
      align-self: center;
    }
    > li {
      margin-right: 10px;
      list-style: none;
      a {
        display: flex;
        line-height: 1rem;
        max-height: 44px;
        padding: 14px 0;
        margin: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
`;

export default NavBar;

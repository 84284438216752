const routes = [
  {
    title: "Home",
    path: "/",
    fetchComponent: () => import(/* webpackChunkName: 'Home' */ "./Home"),
  },
  {
    title: "Team Select",
    path: "/team-select",
    authRequired: true,
    fetchComponent: () =>
      import(/* webpackChunkName: 'TeamSelect' */ "./TeamSelect"),
  },
  {
    title: "My Team View",
    path: "/my-team",
    authRequired: true,
    fetchComponent: () => import(/* webpackChunkName: 'MyTeam' */ "./MyTeam"),
  },
  {
    title: "My Team View",
    path: "/my-team/:teamId",
    authRequired: true,
    fetchComponent: () => import(/* webpackChunkName: 'MyTeam' */ "./MyTeam"),
  },
  {
    title: "Player Transfers",
    path: "/my-team/:teamId/transfers",
    authRequired: true,
    fetchComponent: () => import(/* webpackChunkName: 'MyTeam' */ "./MyTeam"),
  },
  {
    title: "Captain Selection",
    path: "/my-team/:teamId/captains",
    authRequired: true,
    fetchComponent: () => import(/* webpackChunkName: 'MyTeam' */ "./MyTeam"),
  },
  {
    title: "Public Team View",
    path: "/public-team/:teamId",
    authRequired: true,
    fetchComponent: () =>
      import(/* webpackChunkName: 'PublicTeam' */ "./PublicTeam"),
  },
  {
    title: "Fixtures & Results",
    path: "/fixtures",
    fetchComponent: () =>
      import(/* webpackChunkName: 'Fixtures' */ "./Fixtures"),
  },
  {
    title: "Match Facts",
    path: "/fixtures/:fixtureId",
    fetchComponent: () => import(/* webpackChunkName: 'Result' */ "./Result"),
  },
  {
    title: "Leaderboard",
    path: "/leaderboard",
    authRequired: true,
    fetchComponent: () =>
      import(/* webpackChunkName: 'Leaderboard' */ "./Leaderboard"),
  },
  {
    title: "Leagues",
    path: "/leagues",
    authRequired: true,
    fetchComponent: () => import(/* webpackChunkName: 'Leagues' */ "./Leagues"),
  },
  {
    title: "Join A League",
    path: "/leagues/join",
    authRequired: true,
    fetchComponent: () =>
      import(
        /* webpackChunkName: 'Join A League' */ "./Leagues/Pages/JoinLeague"
      ),
  },
  {
    title: "Joined League",
    path: "/leagues/joined/:leaguePin",
    authRequired: true,
    fetchComponent: () =>
      import(
        /* webpackChunkName: 'Mini League' */ "./Leagues/Pages/MiniLeague"
      ),
  },
  {
    title: "Create A League",
    path: "/leagues/create",
    authRequired: true,
    fetchComponent: () =>
      import(
        /* webpackChunkName: 'Create A League' */ "./Leagues/Pages/CreateLeague"
      ),
  },
  {
    title: "Confirm League",
    path: "/leagues/confirm/:leaguePin",
    authRequired: true,
    fetchComponent: () =>
      import(
        /* webpackChunkName: 'Mini League' */ "./Leagues/Pages/MiniLeague"
      ),
  },
  {
    title: "Mini League Standings",
    path: "/leagues/:leaguePin",
    authRequired: true,
    fetchComponent: () =>
      import(
        /* webpackChunkName: 'Mini League Standings' */ "./Leagues/Pages/MiniLeague"
      ),
  },
  {
    title: "Statistics",
    path: "/stats",
    exact: false,
    fetchComponent: () => import(/* webpackChunkName: 'Stats' */ "./Stats"),
    routes: [
      {
        title: "Player Statistics",
        path: "/stats/player",
        parentPath: "/stats",
        fetchComponent: () =>
          import(/* webpackChunkName: 'Player' */ "./Stats/Views/Player"),
      },
      {
        title: "Top 5 Statistics",
        path: "/stats/top-five",
        parentPath: "/stats",
        fetchComponent: () =>
          import(/* webpackChunkName: 'TopFive' */ "./Stats/Views/TopFive"),
      },
      {
        title: "Team of the Week Statistics",
        path: "/stats/team-of-the-week",
        parentPath: "/stats",
        fetchComponent: () =>
          import(
            /* webpackChunkName: 'TeamOfTheWeek' */ "./Stats/Views/TeamOfTheWeek"
          ),
      },
      {
        title: "League Table",
        path: "/stats/league-table",
        parentPath: "/stats",
        fetchComponent: () =>
          import(
            /* webpackChunkName: 'LeagueTable' */ "./Stats/Views/LeagueTable"
          ),
      },
    ],
  },
  {
    title: "Privacy and Cookies Notice",
    path: "/privacy",
    fetchComponent: () => import("./Privacy"),
  },
  {
    title: "Terms and Conditions",
    path: "/terms",
    fetchComponent: () => import("./Terms"),
  },
  {
    title: "Help",
    path: "/help",
    exact: false,
    fetchComponent: () => import(/* webpackChunkName: 'Help' */ "./Help"),
    routes: [
      {
        title: "Rules",
        path: "/help/*",
        parentPath: "/help",
        fetchComponent: () =>
          import(/* webpackChunkName: 'Fantasy Rules' */ "./Help/Views/Rules"),
        routes: [
          {
            title: "Fantasy Rules",
            path: "/help/fantasy",
            parentPath: "/help",
            fetchComponent: () =>
              import(
                /* webpackChunkName: 'Fantasy Rules' */ "./Help/Views/Rules/Fantasy"
              ),
          },
          {
            title: "Ultimate XI Rules",
            path: "/help/ultimate",
            parentPath: "/help",
            fetchComponent: () =>
              import(
                /* webpackChunkName: 'Ultimate XI Rules' */ "./Help/Views/Rules/Ultimate_XI"
              ),
          },
        ],
      },
    ],
  },
  {
    title: "FAQs",
    path: "/faqs",
    fetchComponent: () => import("./GamesFAQs"),
  },
  {
    title: "Prizes",
    path: "/prizes",
    fetchComponent: () => import("./Prizes"),
  },
  {
    title: "Contact Us",
    path: "/contact",
    fetchComponent: () => import("./Contact"),
  },
  {
    title: "Help",
    path: "/help?ios",
    exact: false,
    fetchComponent: () => import(/* webpackChunkName: 'Help' */ "./Help"),
    routes: [
      {
        title: "Rules",
        path: "/help/*?ios",
        parentPath: "/help?ios",
        fetchComponent: () =>
          import(/* webpackChunkName: 'Fantasy Rules' */ "./Help/Views/Rules"),
        routes: [
          {
            title: "Fantasy Rules",
            path: "/help/fantasy?ios",
            parentPath: "/help?ios",
            fetchComponent: () =>
              import(
                /* webpackChunkName: 'Fantasy Rules' */ "./Help/Views/Rules/Fantasy"
              ),
          },
          {
            title: "Ultimate XI Rules",
            path: "/help/ultimate?ios",
            parentPath: "/help?ios",
            fetchComponent: () =>
              import(
                /* webpackChunkName: 'Ultimate XI Rules' */ "./Help/Views/Rules/Ultimate_XI"
              ),
          },
        ],
      },
    ],
  },
  {
    title: "Games FAQs",
    path: "/faqs?ios",
    fetchComponent: () => import("./GamesFAQs"),
  },
  {
    title: "Prizes",
    path: "/prizes?ios",
    fetchComponent: () => import("./Prizes"),
  },
  {
    title: "Contact Us",
    path: "/contact?ios",
    fetchComponent: () => import("./Contact"),
  },
  {
    title: "Privacy and Cookies Notice",
    path: "/privacy?ios",
    fetchComponent: () => import("./Privacy"),
  },
  {
    title: "Terms and Conditions",
    path: "/terms?ios",
    fetchComponent: () => import("./Terms"),
  },
  {
    title: "404",
    path: "*",
    fetchComponent: () =>
      import(/* webpackChunkName: 'NotFound' */ "./NotFound"),
  },
];

export default routes;

const theme = {
  blue: "#007bff",
  indigo: "#6610f2",
  purple: "#6f42c1",
  pink: "#e83e8c",
  red: "#dc3545",
  orange: "#fd7e14",
  yellow: "#ffc107",
  green: "#28a745",
  teal: "#20c997",
  cyan: "#17a2b8",
  white: "#fff",
  black: "#000",
  grey: "#6c757d",
  greyDark: "#343a40",
  primary: "#11224b",
  secondary: "#02024d",
  primaryText: "#11224b",
  success: "#04a04a",
  darkSuccess: "#037b39",
  info: "#17a2b8",
  warning: "#f9ed6c",
  danger: "#b90002",
  dark: "#242424",
  firefly: "#0a142c",
  light: "#eaeaea",
  galleryGrey: "#ebebeb",
  wildSandWhite: "#f4f4f4",
  boulderGrey: "#7c7c7c",
  boulderGreyTwo: "#777",
  altoGrey: "#d6d6d6",
  altoGreyTwo: "#d8d8d8",
  altoGreyThree: "#e0e0e0",
  regentGrey: "#818C9D",
  regentGrey2: "#858fa9",
  lavenderGrey: "#bcc2cc",
  lobLollyGrey: "#BCC3CC",
  concrete: "#f2f2f2",
  darkCharcoal: "#333333",
  mineShaftGrey: "#353535",
  lightGrey: "#d2d4e4",
  cadetGrey: "#9da7b6",
  shipGrey: "#575756",
  platinum: "#e2e6e9",
  platinumTwo: "#e3e3e3",
  silver: "#bdbdbd",
  silverTwo: "#CCC",
  silverChalice: "#aaa",
  scorpionGrey: "#5b5b5b",
  dustyGrey: "#999",
  dimGrey: "#707070",
  graniteGrey: "#666",
  darkGray: "#adadad",
  linkWater: "#f5f8fd",
  whisperGrey: "#EFEDF7",
  glitter: "#e8eefa",
  gainsboro: "#ddd",
  whiteSmoke: "#f6f7f8",
  snow: "#f9faf9",
  alabaster: "#fafafa",
  gallery: "#f0f0f0",
  brightRed: "#ee0000",
  errorRed: "#d92227",
  monzaRed: "#d2000d",
  errorRedTwo: "#b10e1e",
  redBrown: "#a22526",
  sienna: "#8e2612",
  burntUmber: "#933723",
  caputMortum: "#5d231b",
  rossaCorsa: "#d30101",
  crimson: "#e01336",
  crimsonTwo: "#D7181F",
  harvardCrimson: "#c1011e",
  offYellow: "#fefbe2",
  olivine: "#9aad84",
  pesto: "#817b38",
  shamrockGreen: "#049f49",
  mantis: "#54b74e",
  appleGreen: "#d9f1e5",
  greenGradient: "linear-gradient(rgb(31, 169, 74) 0%, rgb(0, 149, 23) 100%)",
  systemGreen: "#34c759",
  rhinoBlue: "#2d305f",
  arapawaBlue: "#0a0e60",
  phthaloBlue: "#0a1186",
  phthaloBlueTwo: "#0a1186",
  phthaloBlueThree: "#030c7e",
  deepKoamaru: "#171d8b",
  oxfordBlue: "#010748",
  oxfordBlueTwo: "#0a0c39",
  oxfordBlueThree: "#0e1157",
  oxfordBlueFour: "#02024c",
  midnightBlue: "#0e105b",
  ultramarine: "#090f7d",
  deepCoveBlue: "#061636",
  downriverBlue: "#0d1f52",
  stPatrickBlue: "#292a62",
  stPatrickBlueTwo: "#292a66",
  blueBayoux: "#48597D",
  ceruleanBlue: "#375dbf",
  munsell: "#f1f1fa",
  lava: "#d6171e",
  lavaTwo: "#d6181f",
  supernova: "#fec80a",
  limeade: "#6dbb00",
  paleAqua: "#c6d0e4",
  sunglow: "#ffd829",
  cadmiumOrange: "#ee7a31",
  safetyOrange: "#ff7200",
  exclaimRed: "#ff0000",
  manatee: "#8584a7",
  isabelline: "#edeee3",
  laserLemon: "#ffec22",
  springGreen: "#07ef7b",
  smalt: "#01298a",
  skyFantasyTournamentPrimary: "#a6012b",
  skyFantasyTournamentSecondary: "#8a1538",
  skyFantasyTournamentTransparent: "rgb(238, 220, 225)",
  skyFantasyTournamentOverlay: "rgba(138, 21, 56, 0.6)",
  skyFantasyDarkBlue: "rgba(19, 44, 108, 1)",
  skyFantasyOverlayBlue: "rgba(10, 30, 101, 0.25)",
  iphone5: 320,
  iphoneX: 375,
  iphoneXHeight: 812,
  extraSmallScreen: 376,
  smallScreen: 576,
  mediumScreen: 768,
  largeScreen: 992,
  extraLargeScreen: 1200,
  superExtraLargeScreen: 1800,
};

export default theme;

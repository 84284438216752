import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import MenuWrapper from "./styles";
import NavLink from "../../../Components/NavLink";
import Button from "../../../../../Components/Buttons/GeneralButton";
import ExternalLink from "../../../../../Components/Links/ExternalLink";
import { sendInterfaceEvent } from "../../../../../common/utilities/analytics";

function MoreMenu({
  toggleMoreMenu,
  setToggleMoreMenu,
  openFeedbackAlert,
  generateLink,
  wildcardActive,
}) {
  const MoreMenuRef = useRef();
  const CDN_PATH = process.env.SKY_BET_FANTASY_CDN_ENDPOINT;

  const handleClick = (e) => {
    if (
      !toggleMoreMenu ||
      (toggleMoreMenu &&
        MoreMenuRef &&
        MoreMenuRef.current &&
        MoreMenuRef.current.contains(e.target))
    ) {
      return;
    }
    setToggleMoreMenu(false);
  };

  useEffect(() => {
    window.addEventListener("click", handleClick);
    return () => window.removeEventListener("click", handleClick);
  }, []);

  const feedbackBtnPressed = () => {
    sendInterfaceEvent({
      text: "Help - Feedback",
      module: "header"
    })
    openFeedbackAlert();
  }

  return (
    <MenuWrapper
      data-testid="header-nav-more-menu"
      className="more-menu-container"
      ref={MoreMenuRef}
      wildcardActive={wildcardActive}
    >
      <li data-testid="header-nav-rules-help">
        <NavLink to="/help" text="Help - Rules" module="header" position="1" module_display_order="2">
          Rules
        </NavLink>
      </li>
      <li data-testid="header-nav-prizes">
        <NavLink to="/prizes" text="Help - Prizes" module="header" position="2" module_display_order="2">
          Prizes
        </NavLink>
      </li>
      <li data-testid="header-nav-contact-us">
        <NavLink to="/contact" text="Help - Contact Us" module="header" position="3" module_display_order="2">
          Contact
        </NavLink>
      </li>
      <li data-testid="header-nav-terms-and-conditions">
        <NavLink to="/faqs" text="Help - FAQ's" module="header" position="4" module_display_order="2">
          FAQ&apos;s
        </NavLink>
      </li>
      <li data-testid="header-nav-terms-and-conditions">
        <NavLink to="/terms" text="Help - T&C's" module="header" position="5" module_display_order="2">
          T&amp;C&apos;s
        </NavLink>
      </li>
      <li data-testid="header-nav-feedback">
        <NavLink to="#" text="Feedback">
          <Button type="button" clickEvent={feedbackBtnPressed}>
            Feedback
          </Button>
        </NavLink>
      </li>
      <ul className="product-bar">
        <li data-testid="header-nav-super-6" className="external-link">
          <ExternalLink 
            href={generateLink("super6")}
            module="header" 
            position="1" 
            module_display_order="2" 
            event_context="cross sell"
            text="super6"
          >
            <img src={`${CDN_PATH}/products/super6_alt.png`} alt="Super6" />
          </ExternalLink>
        </li>
        <li data-testid="header-nav-itv7" className="external-link">
          <ExternalLink 
            href={generateLink("itv7")}
            module="header" 
            position="2" 
            module_display_order="2" 
            event_context="cross sell"
            text="itv7"
          >
            <img src={`${CDN_PATH}/products/itv7.png`} alt="itv7" />
          </ExternalLink>
        </li>
        <li data-testid="header-nav-sporting-life" className="external-link">
          <ExternalLink
            href={generateLink("sportinglife")}
            module="header" 
            position="1" 
            module_display_order="2" 
            event_context="cross sell"
            text="sporting life"
          >
            <img
              src={`${CDN_PATH}/assets/sportinglife.png`}
              alt="Sportinglife"
            />
          </ExternalLink>
        </li>
      </ul>
    </MenuWrapper>
  );
}

MoreMenu.propTypes = {
  toggleMoreMenu: PropTypes.bool,
  wildcardActive: PropTypes.bool,
  setToggleMoreMenu: PropTypes.func,
  openFeedbackAlert: PropTypes.func,
  generateLink: PropTypes.func,
};

MoreMenu.defaultProps = {
  toggleMoreMenu: false,
  wildcardActive: false,
  setToggleMoreMenu: () => {},
  openFeedbackAlert: () => {},
  generateLink: () => {},
};

export default MoreMenu;

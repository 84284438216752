import styled from "styled-components";

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const Label = styled.label`
  font-size: 12px;
  line-height: 1;
  pointer-events: none;
  transition: all 0.25s ease-out 0s;
  color: ${({ theme }) => theme.secondary};
  display: ${({ showLabel }) => (!showLabel ? "none" : "block")};

  &.floating {
    color: ${({ theme }) => theme.secondary};
  }

  &.error {
    color: ${({ theme }) => theme.errorRed};
  }

  &.error-empty {
    font-size: 16px;
    color: ${({ theme }) => theme.errorRed};
    opacity: 0.5;
  }
`;

export const Input = styled.input`
  color: ${({ theme }) => theme.primary};
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  height: 40px;
  margin: 4px 0 16px;
  padding: 0px 10px;
  position: relative;
  border: ${(props) =>
    props.hasError
      ? `1px solid ${props.theme.errorRed}`
      : `1px solid ${props.theme.lightGrey}`};
  border-radius: 4px;
  &::placeholder {
    ${({ hasError, theme }) => {
      if (hasError) {
        return ` {
      color: ${theme.errorRed};
      opacity: 0.5;
    }`;
      }
      return null;
    }}
  }

  &:focus {
    outline: thin solid ${({ theme }) => theme.altoGrey};
    & + label {
      font-size: 12px;
      color: ${({ theme }) => theme.secondary};
      top: 10px;
      left: 0;

      &.floating {
        font-size: 12px;
        color: ${({ theme }) => theme.secondary};
        top: 10px;
        left: 0;
        opacity: 1;
      }

      &.error {
        font-size: 12px;
        color: ${({ theme }) => theme.errorRed};
        top: 10px;
        left: 0;
        opacity: 1;
      }

      &.error-empty {
        font-size: 16px;
        color: ${({ theme }) => theme.errorRed};
        top: 37px;
        left: 10px;
        opacity: 0.5;
      }
    }
  }
`;

Input.RevealPassword = styled.span`
  position: absolute;
  top: 14px;
  right: 14px;
  text-transform: uppercase;
  font-weight: 500;
  cursor: pointer;
`;

export const ErrorMessage = styled.div`
  margin-left: auto;
  display: flex;
  font-size: 12px;
  color: ${({ theme }) => theme.errorRed};
`;

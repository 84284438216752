import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { Link as NavLink, matchPath } from "react-router-dom";

import RouteContext from "../../../common/contexts/RouteContext";
import { sendNavigationEvent } from "../../../common/utilities/analytics";

const AUTH_REDIRECT = process.env.SKY_BET_FANTASY_OAUTH_REDIRECT;

const linkClicked = (data) => {
  if (window.location.pathname !== data.to) {
    sendNavigationEvent({
      text: data.text,
      module: data.module,
      position: data.position,
      order: data.module_display_order,
      url: `${AUTH_REDIRECT}${data.to}`,
      context: data.event_context
    });
  }
};

const Link = ({ to, ...rest }) => {
  const routes = useContext(RouteContext);
  useEffect(() => {
    const targetRoutes = routes.filter(route => matchPath(to, route));
    targetRoutes.forEach(({ fetchComponent }) => {
      if (fetchComponent) {
        fetchComponent();
      }
    });
  }, [routes]);
  return <NavLink to={to} onClick={() => linkClicked({to, ...rest})} {...rest} />;
};

Link.propTypes = {
  to: PropTypes.string
};

Link.defaultProps = {
  to: "/"
};

export default Link;

import styled from "styled-components";
import IconChevronDown from "./assets/Icons_Chevron_Down.svg";

export const SelectWrapper = styled.div`
  position: relative;

  &:after {
    content: url(${IconChevronDown});
    transform: scale(0.8);
    right: 10px;
    top: 0;
    position: absolute;
    pointer-events: none;
    text-align: center;
    line-height: 38px;
    background-color: #fff;
    color: ${({ theme }) => theme.primary};
    border-left: none;
  }
`;

export const SelectInput = styled.select`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  height: 38px;
  border: 1px solid
    ${({ theme, error }) => (error ? theme.errorRed : theme.primary)};
  border-radius: 4px;
  padding: 0 10px;
  color: ${({ theme }) => theme.primary};
  font-size: 16px;
  font-family: Sky;
  font-weight: 400;
  background: none;
  cursor: pointer;

  &:focus {
    outline: thin solid ${({ theme }) => theme.primary};
  }
`;

import React from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { StickyMobileNavLink, StickyMobileNavIconWrapper } from "./styles";

function MobileNavLink({ to, text, children, ...rest }) {
  const { pathname } = useLocation();

  function checkActive(path, linkTo) {
    if (path === linkTo) {
      return 1;
    }
    if (
      (linkTo.includes("my-team") || linkTo.includes("team-select")) &&
      (path.includes("my-team") || path.includes("team-select"))
    ) {
      return 1;
    }
    if (
      (linkTo.includes("/leagues") && path.includes("/leagues")) || 
      (linkTo.includes("/stats") && path.includes("/stats"))
    ) {
      return 1;
    }
    return 0;
  }

  return (
    <StickyMobileNavLink
      to={to}
      aria-label={text}
      active={checkActive(pathname, to)}
      text={text}
      {...rest}
    >
      <StickyMobileNavIconWrapper active={checkActive(pathname, to)}>
        {children}
      </StickyMobileNavIconWrapper>
    </StickyMobileNavLink>
  );
}

MobileNavLink.propTypes = {
  to: PropTypes.string,
  text: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
};

MobileNavLink.defaultProps = {
  to: "/",
  text: "",
};

export default MobileNavLink;

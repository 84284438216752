import styled from "styled-components";
import Link from "../../../../Components/Links/Link";

const NavigationLink = styled(Link)`
  color: ${({ theme }) => theme.primary};
  font-weight: ${({ active }) => (!active ? 400 : 500)};
  transform: font-weight 0.2s ease-in-out;
  font-size: 1.125rem;
  line-height: 1.5rem;
  width: 100%;
  display: block;
  text-decoration: none;
  text-align: left;
  padding: 8px 16px;
  border-bottom: ${({ active, theme, wildcardActive }) =>
    !active
      ? "none"
      : `3px solid ${!wildcardActive ? theme.primary : theme.white}`};
  &:before {
    display: block;
    content: attr(data-link);
    font-weight: bold;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }
  &:hover {
    font-weight: 500;
  }
  @media (min-width: ${({ theme }) => `${theme.largeScreen}px`}) {
    border-left: none;
    color: ${({ theme }) => theme.secondary};
  }
`;

export default NavigationLink;

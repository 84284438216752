import styled from "styled-components";

const FeedbackAlertContainer = styled.div`
  position: relative;
  width: 100%;
  padding: 20px 40px;
  background-image: linear-gradient(180deg, #ebedef, #edf2f5);
  border-radius: 8px;

  @media (min-width: ${({ theme }) => `${theme.largeScreen}px`}) {
    max-width: 800px;
  }
`;

FeedbackAlertContainer.Close = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;

  button {
    padding: 0;
  }
`;

FeedbackAlertContainer.Main = styled.div`
  display: flex;
  flex-direction: column;

  h2 {
    font-size: 1.375rem;
    text-align: center;
    margin-bottom: 8px;
  }
`;

export default FeedbackAlertContainer;

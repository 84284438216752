import styled from "styled-components";

const SiteBanner = styled.div`
  display: flex;
  justify-content: center;
  background: ${({ theme, wildcard, tournamentMode }) => {
    if (tournamentMode && !wildcard) {
      return theme.skyFantasyTournamentTransparent;
    }
    if (wildcard) {
      return `${theme.sunglow}`;
    }
    return `${theme.munsell}`;
  }};
  > div {
    display: inherit;
    align-items: center;
    justify-content: center;
    color: ${({ theme, tournamentMode, wildcard }) => {
      if (tournamentMode && !wildcard) {
        return theme.skyFantasyTournamentSecondary;
      }
      return theme.primary;
    }};
    padding: 4px;
    span {
      font-size: 11px;
      line-height: 1.4;
      text-transform: uppercase;
      &.title {
        font-weight: 500;
        margin-right: 4px;
      }
      &.number {
        font-weight: 500;
      }
    }
    @media (min-width: ${({ theme }) => `${theme.iphoneX}px`}) {
      span {
        font-size: 13px;
      }
    }
    @media (min-width: ${({ theme }) => `${theme.largeScreen}px`}) {
      justify-content: ${({ wildcard }) =>
        !wildcard ? "flex-start" : "center"};
      padding: 8px 16px;
      span {
        font-size: 1.25rem;
        &:not(.title) {
          text-transform: ${({ wildcard }) =>
            !wildcard ? "capitalize" : "uppercase"};
        }
      }
    }
    @media (min-width: ${({ theme }) => `${theme.extraLargeScreen}px`}) {
      padding: 8px 0;
    }
  }
`;

export default SiteBanner;

import React, { useEffect } from "react";
import { createPortal } from "react-dom";
import ModalOverlay from "./styles";

const modalRoot = document.createElement("div");
modalRoot.setAttribute("id", "modal");
document.body.appendChild(modalRoot);

function Modal({ children, playerCard }) {
  const element = document.createElement("div");

  useEffect(() => {
    if (children) {
      document.querySelector("body").style.overflowY = "hidden";
    }
    return () => {
      document.querySelector("body").style.overflowY = "auto";
    };
  }, []);

  useEffect(() => {
    modalRoot.appendChild(element);
    return () => modalRoot.removeChild(element);
  }, [element]);

  return createPortal(
    <ModalOverlay playerCard={playerCard}>{children}</ModalOverlay>,
    element
  );
}

export default Modal;

import React from "react";
import PropTypes from "prop-types";

function IconFixtures({ size, fill }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 20 20"
    >
      <clipPath id="a-fixtures">
        <path
          transform="matrix(1 0 0 -1 0 20)"
          d="M0 10a10 10 0 1 1 10 10C4.477 20 0 15.523 0 10zm14.345 6.9-.254-.536H10l-.91.909v.909H10c1.6 0 3.09-.482 4.345-1.282zm-7.072-.536-1.364-.91H3.636c1.019 1.1 2.628 2.31 4.091 2.728l-.454-1.818zM10.909 15h2.727l1.819-2.727L14.09 10l-2.273-.455-2.273 2.728L10.91 15zm-5.454-3.182-1.11-2.782-2.327-.8a8.07 8.07 0 0 0 .66 5.402L5 13.636l.455-1.818zM18.182 10c0-.98-.189-1.914-.512-2.784l-1.306.057L15.6 9.4l1.364 2.727.818.4c.263-.79.4-1.645.4-2.527zm-9.091.91 1.818-2.274L10 6.364l-2.873-.71-1.672 2.528.909 2.273 2.727.454zm3.182-7.274-.885-1.678a9.25 9.25 0 0 0-.489-.078A6.93 6.93 0 0 0 10 1.818a8.05 8.05 0 0 0-4.318 1.246L6.818 4.09 10 5l2.273-1.364zm3.636 1.819h.91c-.91-1.364-2.274-2.428-3.81-3.037l.627 1.218 2.273 1.819z"
          fillRule="evenodd"
        />
      </clipPath>
      <g clipPath="url(#a-fixtures)">
        <clipPath id="b-fixtures">
          <path transform="matrix(1 0 0 -1 0 20)" d="M-328-48H47v1348h-375z" />
        </clipPath>
        <g clipPath="url(#b-fixtures)">
          <path d="M-5-5h30v30H-5z" fill={fill} />
        </g>
      </g>
    </svg>
  );
}

IconFixtures.propTypes = {
  size: PropTypes.number,
  fill: PropTypes.string,
};

IconFixtures.defaultProps = {
  size: 20,
  fill: "#aaa",
};

export default IconFixtures;

import React from "react";
import PropTypes from "prop-types";
import { sendNavigationEvent } from "../../../common/utilities/analytics";

const linkClicked = (data) => {
  sendNavigationEvent({
    text: data.text,
    module: data.module,
    position: data.position,
    order: data.module_display_order,
    url: data.href,
    context: data.event_context
  });
};

function ExternalLink({ href, children, ...rest }) {
  if (!href) return null;
  return (
    <a href={href} target="_blank" rel="noopener noreferrer" onClick={() => linkClicked({href, ...rest})}>
      {children}
    </a>
  );
}

ExternalLink.propTypes = {
  href: PropTypes.string,
  children: PropTypes.node,
};

ExternalLink.defaultProps = {
  href: "#",
  children: null,
};

export default ExternalLink;

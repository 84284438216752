import { createGlobalStyle } from "styled-components";

import SkyRegular from "../../common/assets/fonts/Sky/SkyRegular.ttf";
import SkyMedium from "../../common/assets/fonts/Sky/SkyMedium.ttf";
import SkyBold from "../../common/assets/fonts/Sky/SkyBold.ttf";
import SkyItalic from "../../common/assets/fonts/Sky/SkyItalic.ttf";
import SSportsDRegular from "../../common/assets/fonts/Sky/SSportsD-Regular.ttf";
import SSportsDMedium from "../../common/assets/fonts/Sky/SSportsD-Medium.ttf";
import SSportsDBold from "../../common/assets/fonts/Sky/SSportsD-Bold.ttf";

const GlobalStyle = createGlobalStyle`
@font-face {
  font-family: "Sky";
  src: url('${SkyRegular}') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Sky";
  src: url('${SkyMedium}') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Sky";
  src: url('${SkyBold}') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Sky";
  src: url('${SkyItalic}') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "SSports";
  src: url('${SSportsDRegular}') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SSports";
  src: url('${SSportsDMedium}') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SSports";
  src: url('${SSportsDBold}') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

*, *::before, *::after {
  box-sizing: border-box;
}

  html, body {
    margin: 0;
    padding: 0;
    width: 100%;
  }

  body {
    font-family: 'Sky', sans-serif;
    background: ${({ theme }) => theme.white};
    color: ${({ theme }) => theme.primary};
    line-height: 1.5;
    overflow-x: hidden;
  }

  .app {
    height: 100vh;
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    font-weight: 500;
    line-height: 1.2;
  }

  p {
    margin: 0;
  }

  input {
    font-family: 'Sky', sans-serif;
  }

  // Pull to Refresh overrides
  .ptr, .ptr-children {
    height: initial !important;
  }
  .ptr__children {
    @media (min-width: ${({ theme }) => `${theme.largeScreen}px`}) {
      overflow: initial!important;
    }
  }
`;

export default GlobalStyle;

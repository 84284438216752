import styled from "styled-components";

export const StickyMobileNav = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;

  @media (min-width: ${({ theme }) => `${theme.largeScreen}px`}) {
    position: static;
    display: none;
  }

  ul {
    height: calc(env(safe-area-inset-bottom) + 48px);
    background-color: ${({ theme }) => theme.white};
    display: flex;
    font-size: 12px;
    box-shadow: 0 -2px 16px 0 rgba(0, 0, 0, 0.15),
      0 -1px 0 0 ${({ theme }) => theme.altoGreyThree};
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }
`;

export const StickyMobileNavItem = styled.li`
  text-align: center;
  flex: 1;
  height: 100%;
`;

export const MoreMenuLink = styled.a`
  border-radius: 0;
  padding: 8px 0;
  display: block;
  color: ${({ theme }) => theme.primary};
  background-color: transparent;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
`;

export const MoreMenuIconWrapper = styled.div`
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    overflow: hidden;
    vertical-align: middle;
  }

  .nav-icon {
    fill: ${({ active, theme }) =>
      active ? theme.primary : theme.silverChalice};
  }
`;

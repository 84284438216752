import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: 992px;
  @media (min-width: ${({ theme }) => `${theme.extraLargeScreen}px`}) {
    max-width: 1140px;
  }
  > h2 {
    font-size: 1.5rem;
    line-height: 1.3;
    font-weight: 400;
    display: block;
    padding: 16px;
    width: 100%;
    &.header {
      font-size: 1.5rem;
      padding: 16px;
      margin: 0 0 8px;
      border-bottom: 1px solid ${({ theme }) => theme.gainsboro};
    }
  }
  @media (min-width: ${({ theme }) => `${theme.largeScreen}px`}) {
    > h2,
    h2.header {
      font-size: 2rem;
    }
    ${({ splitView, newsSplitView, newsArticleSplitView }) => {
      if (splitView) {
        return `
      display: flex;
      flex-flow: row wrap;
      > div {
        &:first-of-type {
          flex: 0 0 65%;
          max-width: 65%;
          padding-right: 32px;
        }
        &:nth-of-type(2) {
          flex: 0 0 35%;
          max-width: 35%;
          padding: 8px;
          height: fit-content;
          margin-top: 16px;
        }
        }
      `;
      }
      if (newsSplitView) {
        return `
      display: flex;
      flex-flow: row wrap;
      > div {
        &:first-of-type {
          flex: 0 0 70%;
          max-width: 70%;
          padding-right: 32px;
        }
        &:nth-of-type(2) {
          flex: 0 0 30%;
          max-width: 30%;
          padding: 8px;
          height: fit-content;
          margin-top: 16px;
        }
        }
      `;
      }
      if (newsArticleSplitView) {
        return `
        display: flex;
        flex-flow: row wrap;
        > div {
          &:first-of-type {
            flex: 0 0 70%;
            max-width: 70%;
            padding-right: 32px;
          }
          &:nth-of-type(2) {
            flex: 0 0 30%;
            max-width: 30%;
            padding: 0 8px 8px;
            background: none;
            height: fit-content;
            margin-top: 16px;
          }
          }
        `;
      }
      return null;
    }}
  }
`;

export default Container;

import { useLayoutEffect, useEffect, useState } from "react";
import theme from "../../Pages/theme";

function useWindowSize(size) {
  const [desktopView, setDesktopView] = useState(
    window.innerWidth > theme.largeScreen
  );
  function updateSize() {
    setDesktopView(window.innerWidth > size);
    return () => {};
  }
  useLayoutEffect(() => {
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  useEffect(() => {
    updateSize();
    return () => {};
  }, []);

  return desktopView;
}

export default useWindowSize;

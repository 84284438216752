import React from "react";
import PropTypes from "prop-types";
import Button from "../styles";

function GeneralButton({
  type,
  children,
  clickEvent,
  testid,
  disabled = false,
  styleClass,
}) {
  return (
    <Button
      type={type}
      onClick={clickEvent}
      data-testid={testid}
      disabled={disabled}
      className={styleClass}
    >
      {children}
    </Button>
  );
}

GeneralButton.propTypes = {
  testid: PropTypes.string,
  type: PropTypes.string,
  children: PropTypes.node,
  clickEvent: PropTypes.func,
  disabled: PropTypes.bool,
  styleClass: PropTypes.string,
};

GeneralButton.defaultProps = {
  testid: "",
  type: "",
  children: null,
  clickEvent: () => {},
  disabled: false,
  styleClass: "",
};

export default GeneralButton;

import React, { useState } from "react";
import PropTypes from "prop-types";
import { InputContainer, Label, Input, ErrorMessage } from "./styles";
import {
  isRequiredValidator,
  pinValidator,
} from "../../../common/utilities/inputValidator";

function FormInput({
  name,
  type,
  onChange,
  testid,
  maxLength,
  label,
  value,
  required,
  customError,
  blurEvent,
  passwordRevealText,
  setPasswordRevealText,
  errorStyling,
}) {
  const [error, showError] = useState(false);

  const formInputValidator = (isRequired, inputName, inputValue) => {
    let result = null;

    if (isRequired) {
      result = isRequiredValidator(inputName, inputValue);

      if (result.isValid) {
        switch (inputName) {
          case "username":
            return isRequiredValidator(inputValue);
          case "pin":
            return pinValidator(inputValue);
          default:
            break;
        }
      }
      return result;
    }
    return { isValid: true, msg: null };
  };

  const initCheck = formInputValidator(
    required,
    name,
    value !== null ? value : ""
  );

  const handleOnBlur = () => {
    switch (true) {
      case !initCheck.isValid:
        showError(true);
        break;

      case initCheck.isValid:
        showError(false);
        break;

      default:
        showError(false);
    }
  };

  const getLabelClass = (inputValue) => {
    switch (true) {
      case inputValue.length > 0:
        return "floating";
      case error && inputValue.length > 0:
        return "error";
      case error && inputValue.length === 0:
        return "error-empty";
      default:
        return "";
    }
  };

  const revealPassword = () => {
    if (passwordRevealText === "Show") {
      setPasswordRevealText("Hide");
    } else {
      setPasswordRevealText("Show");
    }
  };

  return (
    <InputContainer>
      {label !== "" && (
        <Label
          htmlFor={name}
          className={getLabelClass(value, name)}
          showLabel={value !== ""}
        >
          {label}
        </Label>
      )}
      {error && !customError && (
        <ErrorMessage data-testid={`${testid}-error`}>
          {initCheck.msg}
        </ErrorMessage>
      )}
      <Input
        name={name}
        id={name}
        type={type}
        onChange={onChange}
        placeholder={label}
        onBlur={customError ? blurEvent : () => handleOnBlur()}
        data-testid={testid}
        maxLength={maxLength}
        value={value}
        hasError={(error && !customError) || errorStyling}
      />
      {name === "leaguepassword" && value.length >= 1 ? (
        <Input.RevealPassword
          role="presentation"
          onClick={() => revealPassword()}
        >
          {passwordRevealText}
        </Input.RevealPassword>
      ) : null}
    </InputContainer>
  );
}

FormInput.propTypes = {
  name: PropTypes.string.isRequired,
  testid: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  maxLength: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  required: PropTypes.bool,
  customError: PropTypes.bool,
  blurEvent: PropTypes.func,
  passwordRevealText: PropTypes.string,
  setPasswordRevealText: PropTypes.func,
  errorStyling: PropTypes.bool,
};

FormInput.defaultProps = {
  onChange: () => {},
  maxLength: "",
  label: "",
  value: "",
  required: true,
  customError: false,
  blurEvent: () => {},
  passwordRevealText: "",
  setPasswordRevealText: () => {},
  errorStyling: false,
};

export default FormInput;

import React from "react";
import PropTypes from "prop-types";
import { SelectWrapper, SelectInput } from "./styles";

function Dropdown({
  testid,
  options,
  onChange,
  value,
  name,
  supportedTeams,
  disabledOption,
  error,
}) {
  return (
    <SelectWrapper>
      <SelectInput
        id={name}
        name={name}
        data-testid={testid}
        onChange={onChange}
        value={value}
        error={error}
      >
        {supportedTeams
          ? options.map((text, i) => (
              <option
                data-testid={`supported-team-${
                  text.id ? text.id : "placeholder"
                }`}
                key={parseInt(i, 10).toString()}
                value={text.id}
              >
                {text.name.trim()}
              </option>
            ))
          : options.map((text, i) => (
              <option
                data-testid={`select-option-${i}`}
                key={parseInt(i, 10).toString()}
                value={text}
                disabled={disabledOption && i === 0}
              >
                {text}
              </option>
            ))}
      </SelectInput>
    </SelectWrapper>
  );
}

Dropdown.propTypes = {
  options: PropTypes.instanceOf(Array),
  testid: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.string,
  name: PropTypes.string,
  supportedTeams: PropTypes.bool,
  disabledOption: PropTypes.bool,
  error: PropTypes.bool,
};

Dropdown.defaultProps = {
  options: [],
  onChange: () => {},
  value: "",
  name: "",
  supportedTeams: false,
  disabledOption: false,
  error: false,
};

export default Dropdown;

import styled from "styled-components";

const Button = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-family: Sky;
  &:focus {
    outline: thin solid ${({ theme }) => theme.altoGrey};
  }
`;

export default Button;

import React, { createContext, useReducer, useEffect } from "react";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import PropTypes from "prop-types";
import * as api from "../../utilities/api";
import { GlobalReducer, initialState } from "./reducer";

dayjs.extend(isBetween);

export const GlobalStateContext = createContext(initialState);
let INITIAL_RENDER = true;

export const GlobalProvider = ({ children }) => {
  const [appState, dispatch] = useReducer(GlobalReducer, initialState);

  const getAppState = async () => {
    const platform = window.Android ? "?platform=android" : "?platform=web";
    const res = await api.getApplicationState(platform).catch((error) => error);

    if (res.data) {
      dispatch({
        type: "STORE_APPLICATION_STATE",
        payload: res.data.state,
      });
      if (res.data.state && res.data.state.wildcard) {
        const { wildcard } = res.data.state;
        const wildcardActive =
          dayjs().isBetween(
            dayjs.utc(wildcard.opens).local(),
            dayjs.utc(wildcard.closes).local(),
            "second"
          ) && !wildcard.preseason;
        dispatch({ type: "SET_WILDCARD_STATUS", payload: wildcardActive });
      }
    }
  };

  useEffect(() => {
    getAppState();
    return () => {};
  }, []);


  const triggerReload = () => {
      if(!INITIAL_RENDER) {
        window.location.reload();
      } else {
        INITIAL_RENDER = false;
      }
    };
  
    useEffect(() => { 
      window.addEventListener("TrackingConsentChange", triggerReload);
  
      return () => {
        window.removeEventListener("TrackingConsentChange", triggerReload);
      }
    }, [])

  return (
    <GlobalStateContext.Provider value={{ appState, dispatch }}>
      {children}
    </GlobalStateContext.Provider>
  );
};

GlobalProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import ContentContainer from "../../../Components/ContentContainer";
import LogoutContainer from "./styles";
import CloseIcon from "../../../common/assets/images/Icons_Close.svg";
import getURLWithConsent from "../../../common/utilities/getURLWithConsent";

const LogoutPanel = ({
  openLogoutPanel,
  handleLogout,
  handleHardLogout,
  close,
}) => {
  const AUTH_API = process.env.SKY_BET_FANTASY_OAUTH_ENVIRONMENT;
  const AUTH_REDIRECT = process.env.SKY_BET_FANTASY_OAUTH_REDIRECT;
  const CDN_PATH = process.env.SKY_BET_FANTASY_CDN_ENDPOINT;

  const goToMyAccount = async () => {
    if (window.Android) {
      window.Android.myAccount();
      return () => {};
    }

    // add onetrust cookie consent string to URL
    const url = getURLWithConsent(`https://${AUTH_API}.skybetservices.com/account?client_id=fantasyfootball_web&redirect_uri=${AUTH_REDIRECT}`);
    window.open(
      url,
      "_self"
    );
    return () => {};
  };

  useEffect(() => {
    if (openLogoutPanel) {
      document.querySelector("body").style.overflowY = "hidden";
    } else {
      document.querySelector("body").style.overflowY = "auto";
    }
  }, [openLogoutPanel]);

  return (
    <LogoutContainer open={openLogoutPanel} data-testid="logout-panel">
      <ContentContainer>
        <LogoutContainer.Header>
          <div>
            <img
              src={`${CDN_PATH}/assets/sky-fantasy-logo.svg`}
              alt="Sky Fantasy Logo"
            />
          </div>
          <div>
            <LogoutContainer.CloseButton type="button" onClick={close}>
              <img src={CloseIcon} alt="Close Icon" />
            </LogoutContainer.CloseButton>
          </div>
        </LogoutContainer.Header>
        <LogoutContainer.Main>
          <LogoutContainer.AccountButton type="button" onClick={goToMyAccount}>
            My Account
          </LogoutContainer.AccountButton>
          <LogoutContainer.LogOutButton type="button" onClick={handleLogout}>
            Log Out
          </LogoutContainer.LogOutButton>
          {window.Android ? (
            <LogoutContainer.HardLogOutButton
              type="button"
              onClick={handleHardLogout}
            >
              Forget My Account From This Device
            </LogoutContainer.HardLogOutButton>
          ) : null}
        </LogoutContainer.Main>
      </ContentContainer>
    </LogoutContainer>
  );
};

LogoutPanel.propTypes = {
  openLogoutPanel: PropTypes.bool,
  handleLogout: PropTypes.func,
  handleHardLogout: PropTypes.func,
  close: PropTypes.func,
};

LogoutPanel.defaultProps = {
  openLogoutPanel: false,
  handleLogout: () => {},
  handleHardLogout: () => {},
  close: () => {},
};

export default LogoutPanel;

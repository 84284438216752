import styled from "styled-components";
import Button from "../../Components/Buttons/styles";

export const SiteFooter = styled.footer`
  display: block;
  padding-top: 24px;
  padding-bottom: 130px;
  background: ${({ theme }) => theme.whiteSmoke};
  color: ${({ theme }) => theme.dimGrey};

  @media (min-width: ${({ theme }) => `${theme.largeScreen}px`}) {
    padding-bottom: 0;
  }
`;

export const FooterList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  font-size: 15px;

  @media (min-width: ${({ theme }) => `${theme.smallScreen}px`}) {
    -webkit-box-orient: initial;
    -webkit-box-direction: initial;
    flex-direction: initial;
  }
`;

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 15px;
  padding: 0 15px;
  margin: 0 auto;

  @media (min-width: ${({ theme }) => `${theme.extraLargeScreen}px`}) {
    max-width: 1140px;
    padding: 0;
  }
`;

export const FooterRow = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 1rem;

  @media (min-width: ${({ theme }) => `${theme.largeScreen}px`}) {
    flex-direction: row;
    align-items: center;
  }

  p {
    margin: 0;

    &.gambling-app-text {
      margin: 16px 0 0;
    }
  }

  a {
    color: ${({ theme }) => theme.dimGrey};
  }

  &:nth-child(3) {
    margin-bottom: 28px;
  }
`;

export const FooterLogoRow = styled(FooterRow)`
  justify-content: flex-start;
  margin-bottom: 0;
`;

export const SkySportsLogo = styled.img`
  vertical-align: middle;
  max-width: 112px;
  margin-right: 20px;
`;

export const GambleAwareLogos = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 32px;

  a {
    display: block;

    &:nth-child(1) {
      max-width: 188px;
      margin-right: 16px;
    }

    &:nth-child(2) {
      max-width: 170px;
    }
  }

  img {
    height: auto;
    max-width: 100%;
  }
`;

export const FeedbackButton = styled(Button)`
  background: ${({ theme }) => theme.white};
  color: ${({ theme }) => theme.primary};
  border: 1px solid ${({ theme }) => theme.coolGrey};
  border-radius: 4px;
  height: 40px;
  padding: 0 1.6rem;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.5;
  cursor: pointer;
  margin: 24px 0 0 0;

  @media (min-width: ${({ theme }) => `${theme.largeScreen}px`}) {
    margin: 0 0 0 30px;
  }
`;

export const FooterLink = styled.li`
  padding: 0.5rem 1rem 0.5rem 0;

  a {
    color: ${({ theme }) => theme.dimGrey};
    font-weight: 500;
  }
`;

export const FooterButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  span {
    font-family: "Sky", sans-serif;
    color: ${({ theme }) => theme.dimGrey};
    font-size: 15px;
    font-weight: 500;
    text-decoration: underline;
  }
`;
